.havaDurumu {
  width: 100%;
  height: 980px;
  margin-top: 2%;
  margin-bottom: 10%;
  padding: 2% 16%;
}

.havaDurumu .weather-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.havaDurumu .weather-header {
  width: 100%;
  height: 40px;
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.havaDurumu .weather-header .titleColor {
  border: solid 1px rgb(248, 113, 113);
}

.havaDurumu .weather-header .WeatherTitle {
  background-color: #F7F7F7;
  padding-bottom: 1%;
}

.havaDurumu .weather-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 1%;
  padding-right: 1%;
  margin-top: 1%;
}
.havaDurumu .downWeather{
gap: 20%;
justify-content: center;
}

.havaDurumu .weather-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.havaDurumu .weather-icon {
  margin-right: 15px;
  font-size: 1.5rem;

}

.havaDurumu .weather-city-list {
  width: 150%;
  max-height: 400px;
  overflow-y: scroll;
}

.havaDurumu .weather-search-input {
  width: 150%;
  padding: 10px;
  margin-bottom: 10px;
  color: black; /* Set placeholder text color */
  background-color: #eee; /* Set background color */
}

.havaDurumu .city-item {
  cursor: pointer;
  padding: 5px 0;
}

.havaDurumu .selected-city {
  font-size: 34px;
  font-weight: bold;
  text-align: right;
  margin-bottom: 10px;
}

.havaDurumu .weather-temperature {
  font-size: 2.5rem;
  font-weight: bold;
}

.havaDurumu .cities .row .stabilCity{
  height: 75px !important;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  font-weight: bolder;
  font-size: 20px;
  background-color: #F7F7F7;
}
.havaDurumu .cities .row .col-6{
height: 100%;
border: solid 1px rgb(218, 214, 214);
}
.havaDurumu .cityAnk:hover{
  background-image: url("./../../components//traffic//city-images/ankara.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: all ease-in-out 0.75s;
  color: yellow;
}
.havaDurumu .cityIzm:hover{
  background-image: url("./../../components//traffic//city-images/izmir.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: all ease-in-out 0.75s;
  color: yellow;
}
.havaDurumu .cityTs:hover{
  background-image: url("./../../components//traffic//city-images/bursa.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: all ease-in-out 0.75s;
  color: yellow;
}
.havaDurumu .cityDbk:hover{
  background-image: url("./../../components//traffic//city-images/gaziantep.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: all ease-in-out 0.75s;
  color: yellow;
}

@media (max-width: 1980px) {
  .havaDurumu {
margin-top: 0%;
  }
 

}
@media (max-width: 1512px) {
  .havaDurumu {
    height: 880px !important;
  }
  .havaDurumu .weather-container {
    height: 800px;
  }
  .havaDurumu .searchBox{
    height: 420px;
  }
  .havaDurumu .weather-city-list {
  height: 370px;
  }
}
@media (max-width: 1280px) {

  .havaDurumu .weather-container {
    height: 740px;
  }

}
@media (max-width: 1200px) {

  .havaDurumu .weather-container {
    height: 530px;
    margin-bottom: 5% !important;
  }

  .havaDurumu .weather-city-list {
    width: 100%;
    max-height: 240px;
  }
  
  .havaDurumu .weather-search-input {
    width: 100%;
  }
  .havaDurumu .selected-city {
    font-size: 28px;
  }
  .havaDurumu .weather-temperature {
    font-size: 26px;
  }
  .havaDurumu .weather-icon{
    font-size: large !important;
  }
}
@media (max-width: 835px) {
  .havaDurumu {
    height: 980px;
    padding: 2% 22%;
  }
  .havaDurumu .weather-container {
    height: 640px;
  }

  .havaDurumu .weather-city-list {
    max-height: 320px;
  }
  .havaDurumu .selected-city {
    font-size: 20px !important;
  }
  .havaDurumu .weather-temperature {
    font-size: 16px;
  }
  .havaDurumu .weather-icon{
    font-size: medium !important;
  }
  .havaDurumu .weather-content .cities{
    padding-left: 2%;
    padding-right: 2%;
  }
}
@media (max-width: 768px) {
  .havaDurumu {
    height: 680px;
    padding: 2% 3%;
  }
  .havaDurumu .weather-container {
    height: 800px;
  }
  
  .havaDurumu .weather-city-list {
    max-height: 420px;
  }
  .havaDurumu .selected-city {
    font-size: 28px !important;
  }
  .havaDurumu .weather-temperature {
    font-size: 26px;
    margin-left: 20%;
  }
  .havaDurumu .weather-icon{
    font-size: large !important;
  }
}
@media (max-width: 430px) {
  .havaDurumu {
    height: 680px;
    padding: 2% 3%;
  }
  .havaDurumu .weather-container {
    height: 800px;
  }
  
  .havaDurumu .weather-city-list {
    max-height: 420px;
  }
  .havaDurumu .selected-city {
    font-size: 26px !important;
  }
  .havaDurumu .weather-temperature {
    font-size: 24px;
    margin-left: 10%;
  }
  .havaDurumu .weather-icon{
    font-size: medium !important;
  }
}
