/* Temel yapılar ve container stilleri */
.mobile-ads-container {
    display: none;
    position: relative;
    width: 75.5%;
    height: 160px;
    overflow: hidden;
    margin-left: 13%;
    margin-top: 1%;
    margin-bottom: -3%;
  }
  
  .mobile-ads-container .slider {
    position: relative;
    width: 100%;
    height: auto;
  }
  
  /* Slider container ve geçiş efektleri */
  .mobile-ads-container .slider-container {
    display: flex;
    transition: transform 1s ease-in-out; 
    width: 100%; 
  }
  
  /* Her bir slide */
  .mobile-ads-container .slide {
    min-width: 100%;
    box-sizing: border-box;
  }
  
  /* Resim stilleri */
  .mobile-ads-container .slideImg img {
    width: 100%;
    height: 140px;
    display: none;
  }

  #canvas{
    height: 130px !important;
  }

  #animation_container{
    height: 130px !important;
  }

  #dom_overlay_container{
    height: 130px !important;
  }

  @media (max-width:820px) {
    .mobile-ads-container{
        display: none;
    }
    .mobile-ads-container .slideImg img{
        display: block;
    }
  }
  @media (max-width:800px) {
    .mobile-ads-container {
      width: 74.5%;
      justify-content: center;
      margin-bottom: -3%;
    }

  }
  @media (max-width:768px) {
    .mobile-ads-container {
      width: 75%;
      justify-content: center;
      margin-bottom: -3%;
    }
  }
  
  @media (max-width:567px) {
    .mobile-ads-container {
      width: 74%;
      margin-left: 13.5%;
      margin-bottom: -3%;
    }
  }
  
  @media (max-width:430px) {
 
    .mobile-ads-container {
      display: flex;
      width: 89%;
      height: 130px;
      margin-left: 6.5%;
    }
    .mobile-ads-container .slideImg img {
      height: 120px;
      display: block;

    }
  }
  @media (max-width:360px) {
    .mobile-ads-container{
        margin-left: 23px;
        height: 130px;
    }

  }
  