
.footer-container{ 
    background-color: #262626;
    border-top: 6px #F73C29 solid;
    /* margin-top: 700px; */
}

.footer-email-input{
  width: 320px;
  background-color: #262626;
  height: 50px;
  border-radius: 50px;
  padding-left: 15px;
  padding-right: 15px;
  border: 3px solid #9a2619;
  outline: none;
}

.footer-email-input:focus{
  background-color: white;
  color: black;
}

.footer-email-button{
  font-size: 36px;
  color: #9a2619;
  position: relative;
  right: 43px;
  bottom: 1px;
}

.footer-email-button:hover{
  color: #771c14
}



.page-link-card{
    text-align: end;
    line-height: 25px;
    height: 60px;
}

/* .page-link-card:hover .page-link{
    color: rgb(107 114 128);
} */

#page-link-card1::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 4px;
    width: 0;
    background-color: #7F8C8D;
    transition: width 0.5s ease-out;
  }
  #page-link-card1:hover::before {
    width: 100%;
  }

  #page-link-card2::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 4px;
    width: 0;
    background-color: #C90914;
    transition: width 0.5s ease-out;
  }
  #page-link-card2:hover::before {
    width: 100%;
  }

  #page-link-card3::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 4px;
    width: 0;
    background-color: #5380B7;
    transition: width 0.5s ease-out;
  }
  #page-link-card3:hover::before {
    width: 100%;
  }

  #page-link-card4::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 4px;
    width: 0;
    background-color: #D7AB51;
    transition: width 0.5s ease-out;
  }
  #page-link-card4:hover::before {
    width: 100%;
  }

  #page-link-card5::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 4px;
    width: 0;
    background-color: #1024DF;
    transition: width 0.5s ease-out;
  }
  #page-link-card5:hover::before {
    width: 100%;
  }


.page-link{
    font-size: 18px;}

.page-link-badge{
    font-size: 10px;
    font-weight: 600;
    padding: 2px;
    border-radius: 3px;
}




  @media (max-width: 1024px) {
    .footer-top-container{
      padding-left: 20px;
    }

    .footer-mid-container{
      padding-left: 20px;
      padding-right: 20px;
    }

    .footer-mid-left-container{
      width: 280px;
    }
  }

  @media (max-width: 768px) {
    .footer-top-container, .footer-mid-container{padding-left: 15px;
    padding-right: 15px;
}


.web-footer-hr{display: none;}

.footer-bottom-text{border: none;}



.footer-top-right-container{
  margin-top: 15px;
}

.footer-container>img{padding-left: 10px;
padding-right: 10px;}

.page-link-card{
    text-align: start;
    line-height: 15px;
    
}

.social-media-container{
  display: inline-flex;
  align-items: center;
  gap: 20px;
}

.footer-mid-container{
  margin-top: 10px;
}

.footer-mid-left-container{
  display: none;
}

.footer-bottom-container {
  margin-top: 8px;
  height: auto;
  padding: 5px 15px;
}

.footer-bottom-text{
  font-size: 12px;
}
  }

  @media (max-width: 700px) {
    .footer-top-container, .footer-mid-container{padding-left: 15px;
    padding-right: 15px;
}

.footer-top-right-container{
  margin-top: 15px;
}

.footer-email-button{
  top: 7px;
}

.footer-container>img{padding-left: 10px;
padding-right: 10px;}

.page-link-card{
    text-align: start;
    line-height: 15px;
    
}

.social-media-container{
  display: inline-flex;
  align-items: center;
  gap: 20px;
}

.footer-mid-container{
  margin-top: 10px;
}

.footer-mid-left-container{
  display: none;
}


  }
