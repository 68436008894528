.live-stream-container {
    margin-bottom: 40px;
    padding: 0% 9%;
    margin-left: -25px;
}

@media (max-width:2560px) {

    .live-stream-link {
        height: 711px;
    }

}

@media (max-width: 1920px) {

    .live-stream-link {
        height: 712px;
    }

}

@media (max-width: 1512px) {

    .live-stream-link {
        height: 573px;
    }

    .live-stream-container {
        margin-bottom: 40px;
        padding: 0% 10%;
        margin-left: -25px;
    }

}

@media (max-width: 1440px) {

    .live-stream-link {
        height: 573px;
    }

    .live-stream-container {
        margin-bottom: 40px;
        padding: 0% 8%;
        margin-left: -25px;
    }
    

}

@media (max-width: 1367px) {

    .live-stream-link {
        height: 537px;
    }

}

@media (max-width: 1280px) {

    .live-stream-link {
        height: 430px;
    }
    .live-stream-container {
        margin-bottom: 40px;
        padding: 0% 14%;
        margin-left: -25px;
    }
}

@media (max-width: 1200px) {

    .live-stream-link {
        height: 410px;
    }

    .live-stream-container {
        margin-bottom: 40px;
        padding: 0% 13%;
        margin-left: -25px;
    }

}

@media (max-width: 1100px) {

    .live-stream-link {
        height: 371px;
    }

}

@media (max-width: 1024px) {

    .live-stream-link {
        height: 315px;
    }

    .live-stream-container {
        margin-bottom: 40px;
        padding: 0% 14%;
        margin-left: -25px;
    }

}

@media (max-width: 991px) {

    .live-stream-link {
        height: 331px;
    }

    .live-stream-container {
        margin-bottom: 40px;
        padding: 0% 10%;
        margin-left: 5px;
    }

}

@media (max-width: 820px) {

    .live-stream-link {
        height: 348px;
    }

    .live-stream-container {
        margin-bottom: 40px;
        margin-top: 50px;
        padding: 0% 0%;
        margin-left: 0px;
    }

}

@media (max-width: 768px) {

    .live-stream-link {
        height: 320px;
    }

}

@media (max-width: 600px) {

    .live-stream-link {
        height: 303px;
    }

}

@media (max-width: 500px) {

    .live-stream-link {
        height: 253px;
    }

}

@media (max-width: 430px) {

    .live-stream-link {
        height: 216px;
    }

}

@media (max-width: 400px) {

    .live-stream-link {
        height: 200px;
    }

}