.hp .colorRed {
  background-color: #f73c29;
}

.hp .colorOrange {
  background-color: #e96f27;
}

.hp .colorGreen {
  background-color: #277f45;
}

.hp .colorSky {
  background-color: #52a79d;
}

.hp .colorLightGreen {
  background-color: #1bb21d;
}

.hp .colorBlue {
  background-color: #4b679b;
}

.hp {
  margin: 2% 2.2% 3% 4%;
  padding: 0%;
}

.hp .container {
  width: 100%;
  /* margin-left: -2px; */
  margin-bottom: 1%;
  margin-top: 2%;
  background-color: transparent;
}

.hp .hp_banner_box {
  width: 100%;
  display: flex;
  justify-content: center;
  object-fit: cover;
  margin-left: -11px;
  padding: 0% 7%;
  margin-bottom: 1px;
  background-color: transparent !important;
}

.hp .hp_banner_left {
  width: 850px;
  height: 500px;
  object-fit: cover;
  overflow: hidden;
}

.hp .hp_banner_right {
  width: 420px;
  height: 500px;
  object-fit: cover;
  overflow: hidden;
}

.hp .slider {
  position: relative;
  width: 100%;
  height: 100%;
}

.hp .slider-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

/* .hp .slider-container div {
  height: 100%;
  width: 100%;
} */

.hp .slider-container div a {
  width: 100%;
  height: 100% !important;
  object-fit: cover;
}

.hp .slider-container div a img {
  width: 100%;
  height: 100% !important;
}

.hp .slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  background-position: center;
  background-size: contain;
  opacity: 0;
  /* transition: opacity 0.7s ease; */
}

.slide {
  transition: transform 0.5s ease, opacity 0.5s ease;
  opacity: 0;
  transform: translateX(100%);
}

.slide.active {
  opacity: 1;
  transform: translateX(0);
}

.slide.prev {
  opacity: 1;
  transform: translateX(-100%);
}

.slide.next {
  opacity: 1;
  transform: translateX(100%);
}


.prev-button, .next-button{
  height: 100%;
  /* border: 2px solid pink; */
  width: 15%;
  z-index: 3;
  position: absolute;
}

.prev-button {
  /* border: 2px solid pink; */
  left: 0;
}

.next-button {
  /* border: 2px solid blueviolet; */
  right: 0;
}

.hp .slide.active {
  opacity: 1;
}

.hp .slide .slideImg {
  width: 110%;
  height: auto;
  object-fit: cover;
  display: block;
}

/* .hp .prev,
.hp .next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 50px;
  padding: 10px;
  z-index: 10;
  opacity: 75%;
}

.hp .prev {
  left: 10px;
}

.hp .next {
  right: 10px;
} */

.hp .numbers {
  text-align: center;
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
}

.hp .number {
  display: none;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #bbb;
  margin: 0 4px;
  cursor: pointer;
  line-height: 20px;
  text-align: center;
  transition: background-color 0.3s ease;
}

.hp .slide-title {
  position: absolute;
  top: 3%;
  right: 3% !important;
  width: max-content !important;
  height: 38px !important;
  background-color: #f73c29;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  color: white;
  z-index: 10;
}

.hp .slide-title h3 {
  background: red;
  font-size: 22px;
  width: 100%;
  height: 100%;
  font-weight: bold;


}

/* .hp .slide-content {
  position: absolute;
  height: 80px !important;
  bottom: 0;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.7) 25%,
    rgba(0, 0, 0, 1) 100%
  );
  display: flex;
  justify-content: start;
  align-items: center;
  padding-left: 1%;
  
  color: white;
  line-height: 24px;
  
}  */
.slide-content {
  position: absolute;
  bottom: 0px;
  box-shadow: rgba(0, 0, 0, 0.85) 0px -200px 80px -40px inset !important;
  height: 100%;
  width: 100%;
  /* border: 2px solid blue; */
}

.top-left-carousel-text {
  color: white;
    padding: 10px;
    padding-bottom: 45px;
    width: 100%;
    font-size: 35px;
    font-weight: 500;
    box-sizing: border-box;
    height: 2.9em;
    text-align: start;
    position: absolute;
    bottom: 45px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 1.2em;
    /* border: 2px solid red; */
  
}

/* .slide-content{
  
  position: absolute;
  bottom: -25px;
  padding-bottom: 45px;
} */

/* .slider-container {
  box-shadow: rgba(0, 0, 0, 0.85) 0px -200px 80px -40px inset !important;
  z-index: 2;
} */





/* .hp .slide-content p{
 
  width: 100%;
  height: 100%;
  padding: 0%;
  line-height: 30px !important;
  display: -webkit-box;
  -webkit-line-clamp: 2;  
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
} */

.hp .number:hover {
  background-color: #717171;
  color: white;
}

.hp .number.active-number {
  background-color: #717171;
  color: white;
}

.hp .newsTitle {
  width: 100%;
  height: 400px;
}

.hp .newsTop,
.hp .newsDown {
  height: 500px;
  display: flex;
  flex-direction: column;
}

.hp .downLinks .imgBox img {
  height: 250px;
}

.hp .imgBox {
  position: relative;
  width: 100%;
  max-width: 850px;
  height: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  transition: all ease-in-out 0.3s;
  object-fit: cover;
  overflow: hidden;
  border: solid 1px white;
}

.hp .imgBox:hover {
  filter: none;
  cursor: pointer;
}

.hp .imgBox img {
  transition: transform 0.3s ease; 
}

.hp .imgBox:hover img {
  transform: scale(1.07); 
}


.hp .image {
  display: inline-flex;
  width: 100%;
  height: 100%;
  display: block;
}

.hp .overlay {
  position: absolute;
  top: -10%;
  left: 3%;
  padding: 0% 2%;
  border-radius: 4px;
  font-size: 11px;
  font-weight: 500;
  z-index: 2;
  color: white;
}


.category-badge {
  font-size: 14px;
  font-weight: 600;
  background-color: #F73C29;
  width: max-content;
  padding: 5px 10px;
  border-radius: 50px;
  position: absolute;
  top: -18px;
  left: 0px;
}

.hp .overlayTwo {
  position: absolute;
  width: 100%;
  height: 25%;
  bottom: 0%;
  background: linear-gradient(to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.5) 50%,
      rgba(0, 0, 0, 0.8) 100%);
  z-index: 2;
  display: flex;
  /* padding-bottom: 1% !important; */
  /* border: solid blue 1px; */
}

.hp .overlayTwo p {
  display: inline-block;
  width: 100%;
  /* height: 100%; */
  z-index: 1;
  font-weight: 400;
  font-size: 16px !important;
  line-height: 18px;
  padding-left: 3%;
  padding-bottom: 0%;
  padding-top: 3%;
  color: white;
  overflow: hidden;
  /* border: solid 1px red; */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* Gösterilecek maksimum satır sayısı */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.hp .overlayTwo::before {
  content: "";
  position: absolute;
  top: 0%;
  left: 0;
  width: 100%;
  height: 100%;
  /* border: solid blue; */
  background: linear-gradient(to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.5) 25%,
      rgba(0, 0, 0, 0.9) 100%);
}

.hp .title {
  background-color: #333;
  color: #fff;
  padding: 10px;
  text-align: center;
}

.hp .downLinks {
  width: 100%;
  /* height: 550px !important; */
  padding: 0% 8%;
  display: flex;
  flex-direction: column;
  object-fit: cover;
  overflow: hidden;
}

.hp .downLinks .row {
  width: 100%;
  height: 100% !important;
}

.hp .downLinks .overlayTwo p {
  line-height: 23px;
  padding-top: 2% 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* Gösterilecek maksimum satır sayısı */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.hp .downLinks .row .col-4 .imgBox img {
  /* border: solid 1px white; */
  /* height: 100%; */
  object-fit: cover;
}

.hp .downLinks .row .col-4 {
  padding: 0;
}

/* Yeni butonların konumu ve görünümü */
.top-carousel-buttons-container {
  display: flex;
    justify-content: center;
    position: absolute;
    bottom: 10px;
    width: 100%;
    z-index: 4;
}

.page-button {
  margin: 0 5px;
    padding: 2px 0;
    width: 30px;
    color: white;
    font-weight: 700;
    /* border: none; */
    /* background-color: #ccc; */
    cursor: pointer;
    /* border-radius: 5px; */
    /* transition: all 0.2s; */
    border-color: #F73C29;
}

.page-button:hover {
  border-bottom: 6px solid #F73C29;
}

.page-button.active-button {
  color: white;
    border-bottom: 6px solid #F73C29;
}



@media (max-width:1512px) {
  .category-badge {
    height: 25px;
    width: max-content;
  }

  .hp .downLinks .imgBox img {
    height: 200px;
  }

  .hp .downLinks .overlayTwo p {
    line-height: 19px;
    padding-top: 4%;
    -webkit-line-clamp: 2;
    /* Gösterilecek maksimum satır sayısı */

  }

  .hp .hp_banner_left {
    width: 680px;
    height: 400px;
  }

  .hp .hp_banner_right {
    width: 340px;
    height: 400px;
  }

  .hp .newsTop,
  .hp .newsDown {
    height: 400px;
    display: flex;
    flex-direction: column;
  }

  .hp .downLinks {
    height: 450px !important;
    padding: 0% 9%;
  }

  .top-left-carousel-text {
    font-size: 25px !important;
    /* line-height: 30px !important; */
    bottom: 45px;
  }

  .page-button {
    margin: 0 5px;
    padding: 2px 0;
    width: 21px;
    font-size: 14px;
    color: white;
    font-weight: 700;
    border: none; 
}
}

@media screen and (max-width: 1440px) {
  .hp .downLinks .overlayTwo p {
    line-height: 21px;
    padding-top: 2% 0;
    -webkit-line-clamp: 2;
    /* Gösterilecek maksimum satır sayısı */
  }

  .hp .hp_banner_left {
    width: 680px;
    height: 400px;
  }

  .hp .hp_banner_right {
    width: 340px;
    height: 400px;
  }

  .hp .newsTop,
  .hp .newsDown {
    height: 400px;
    display: flex;
    flex-direction: column;
  }

  .hp .downLinks {
    height: 450px !important;
    padding: 0% 7%;
  }

  .page-button {
    margin: 0 5px;
    padding: 2px 0;
    width: 21px;
    font-size: 14px;
    color: white;
    font-weight: 700;
    border: none; 
}
}

@media screen and (max-width: 1280px) {
  .top-left-carousel-text {
    font-size: 20px !important;
    /* line-height: 25px !important; */
    bottom: 45px;
    height: 3em;
  }

  .page-button {
    margin: 0 3px;
    padding: 2px 0;
    width: 18px;
    font-size: 11px;
    color: white;
    font-weight: 700;
    border: none; 
    
}

  .hp .downLinks .imgBox img {
    height: 150px;
  }

  /* .hp .slide-content {
    padding-top: 2%;
    line-height: 28px;
  } */
  .hp .hp_banner_right .overlayTwo p {
    line-height: 28px;
    -webkit-line-clamp: 1;
    font-size: 12px !important;
    /* Gösterilecek maksimum satır sayısı */
  }

  .hp .hp_banner_left {
    width: 510px;
    height: 300px;
  }

  .hp .hp_banner_right {
    width: 255px;
    height: 300px;
  }

  .hp .newsTop,
  .hp .newsDown {
    height: 300px;
  }

  .hp .downLinks {
    height: 300px !important;
    padding: 0% 12.8%;
  }

  .hp .downLinks .row .col-4 {
    height: 150px !important;
  }

  .hp .downLinks .row .col-4 .text-left {
    font-size: 12px !important;
    line-height: 28px;
    -webkit-line-clamp: 1;
  }

  .slide-content {
    box-shadow: rgba(0, 0, 0, 0.85) 0px -200px 80px -80px inset !important;
  }
}

@media screen and (max-width: 1200px) {
  .top-left-carousel-text {
    font-size: 18px !important;
    /* line-height: 18px !important; */

  }

  .hp .hp_banner_box {
    padding: 0 12%;
    background-color: white;
  }

  .hp .hp_banner_left,
  .hp .hp_banner_right {
    background-color: white;
    padding: 0%;
    height: 100%;
  }

  .hp .downLinks {
    padding: 0 12% !important;
    transition: all ease-in-out 0.3s;
  }

  .hp .downLinks .row {
    height: 175px;
  }

  .hp .slider {
    position: relative;
    width: 100%;
  }

  .hp .slider-container {
    position: relative;
    width: 100%;
    height: 256px;
    overflow: hidden;
  }

  .hp .slider-container div {
    width: 100%;
    height: 100%;
  }

  .hp .slider-container div a {
    height: 100% !important;
  }

  .hp .slider-container div a img {
    height: 100% !important;
  }

  .hp .newsTitle {
    height: 256px;
  }

  .hp .newsTop,
  .hp .newsDown {
    height: 256px;
  }

  .hp .downLinks {
    display: flex;
    margin-left: 2px;
  }

  .hp .downLinks .imgBox img {
    height: 128px;
  }

  .hp .overlay {
    top: -15%;
    font-size: 10px;
  }

  .category-badge {
    height: 20px;
    width: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px auto;
  }

  .hp .overlayTwo {
    height: 25%;
  }

  .hp .hp_banner_right .overlayTwo p {
    padding-bottom: 5%;
    font-size: 13px !important;
  }

  .hp .downLinks .overlayTwo p {
    padding-bottom: 7%;
    line-height: 23px !important;
    font-size: 13px !important;
  }
}

@media screen and (max-width: 1024px) {

  .top-left-carousel-text {
    font-size: 15px !important;
    line-height: 20px !important;
    bottom: 35px;
    /* height: 40px !important; */
  }

  .page-button {
    margin: 0 2.5px;
    padding: 2px 0;
    width: 13px;
    font-size: 10px;
    color: white;
    font-weight: 700;
    border: none; 
    
}

.page-button:hover {
  border-bottom: 4px solid #F73C29;
}

.page-button.active-button {
  color: white;
    border-bottom: 4px solid #F73C29;
}

  /* .hp .slide-content {
    height: 60px !important;
    padding-top: 2%;
    font-size: large;
  } */
  /* .hp .slide-content p{
    
    width: 100%;
    padding: 1.2% 0 0% 0;
    line-height: 21px !important;
    display: -webkit-box;
    -webkit-line-clamp: 2; 
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  } */

  .hp .overlay {
    top: 10%;
    font-size: 9px;
  }

  .hp .hp_banner_left {
    width: 510px;
    height: 260px;
    /* border: solid red; */
  }

  .hp .hp_banner_left .slider .slider-container {
    /* width: 510px; */
    height: 260px;
    /* border: solid red; */
  }

  .hp .hp_banner_right {
    width: 255px;
    height: 260px;
    /* border: solid red; */
  }

  .hp .newsTop,
  .hp .newsDown {
    height: 260px;
  }

  .hp .downLinks {
    height: 260px !important;
    padding: 0% 11.8% !important;
  }

  .hp .downLinks .row .col-4 {
    height: 130px !important;
  }

  .slide-content {
    box-shadow: rgba(0, 0, 0, 0.85) 0px -200px 80px -80px inset !important;
  }
}

@media screen and (max-width: 991px) {
  .top-left-carousel-text {
    font-size: 20px !important;
    line-height: 24px !important;
bottom: 40px;
  }

  .page-button {
    margin: 0 5px;
    padding: 2px 0;
    width: 18px;
    font-size: 14px;
    color: white;
    font-weight: 700;
    border: none; 
}

  .hp .container {
    margin-top: 0%;
  }

  .hp .hp_banner_box {
    flex-direction: column;
    padding: 0 12%;
  }

  /* 
  .hp .hp_banner_left,
  .slider {
    height: 300px !important;
  } */
  /* .hp .hp_banner_left,
  .hp .hp_banner_right {
    width: 100%;
    height: 180px;
    padding: 0%;
  } 
  .hp .hp_banner_right .newsTop {
    width: 100%;
    height: 180px;
    flex-direction: row !important;
  }*/


  .hp .downLinks .row {
    height: auto !important;
    padding: 0 !important;
    margin: 0 !important;
  }

  /* .hp .slider {
    position: relative;
    width: 100%;
    height: 356px;
  }
  .hp .slider-container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
  } */
  .hp .overlay {
    top: -10%;
    font-size: 9px;
  }

  /* .hp .hp_banner_right {
    height: 150px !important;
  }
  .hp .hp_banner_right .newsTop {
    height: 150px !important;
  } */
  .hp .hp_banner_right .overlay {
    top: 15%;
  }

  .hp .overlayTwo {
    padding-bottom: 0%;
  }

  .hp .hp_banner_right .overlayTwo p {
    font-size: 12px !important;
  }

  .hp .downLinks {
    display: flex;
    margin-left: -10px;
    height: 250px !important;
  }

  .hp .downLinks .overlayTwo {
    height: 35px;
  }

  .hp .downLinks .overlayTwo p {
    margin: 0;
    font-size: 11px !important;
  }

  /* .hp .slide-content {
    height: 70px !important;
    padding-top: 2%;
    font-size: x-large;
  } */
  .hp .hp_banner_left {
    width: 595px !important;
    height: 350px;
    /* border: solid red; */
  }

  .hp .hp_banner_left .slider .slider-container {
    width: 595px;
    height: 350px;
    /* border: solid red; */
  }

  .hp .hp_banner_right {
    width: 595px;
    height: 210px;
    /* border: solid red; */
  }

  .hp .hp_banner_right .newsTitle {
    display: flex;
    flex-direction: row !important;

  }

  .hp .newsTop,
  .hp .newsDown {
    width: 595px;
    height: 210px;
    display: flex;
    flex-direction: row;
  }

  .hp .downLinks {
    margin-left: 0.5%;
    height: 260px !important;
    padding: 0% 10% !important;
  }

  .hp .downLinks .row .col-4 {
    height: 130px !important;
  }

  .hp .hp_banner_right .overlayTwo p {
    /* border: solid red; */
    margin-top: 1%;
    line-height: 18px !important;
    font-size: 13px !important;
    display: -webkit-box;
    -webkit-line-clamp: 2;
  }
}

@media screen and (max-width: 835px) {
  .top-left-carousel-text {
    font-size: 18px !important;
    /* line-height: 18px !important; */
    bottom: 40px;
  }

  .hp .container {
    margin-left: 1%;
  }

  /* .hp .hp_banner_left,
  .slider {
    height: 280px !important;
  } */
  .hp .hp_banner_left {
    width: 450px !important;
    height: 250px;
    /* border: solid red; */
  }

  .hp .hp_banner_left .slider .slider-container {
    width: 450px;
    height: 250px;
    /* border: solid red; */
  }

  .hp .hp_banner_right {
    width: 450px;
    height: 150px;
    /* border: solid red; */
  }

  .hp .hp_banner_right .newsTitle {
    display: flex;
    flex-direction: row !important;

  }

  .hp .newsTop,
  .hp .newsDown {
    width: 450px;
    height: 150px;
    display: flex;
    flex-direction: row;
  }

  .hp .downLinks {
    margin-left: -2.8%;
    height: 260px !important;
    padding: 0% 13% !important;
  }

  .hp .downLinks .row .col-4 {
    height: 130px !important;
  }

  .hp .hp_banner_right .overlayTwo p {
    /* border: solid red; */
    margin-top: 1%;
    line-height: 25px !important;
    font-size: 12px !important;
    display: -webkit-box;
    -webkit-line-clamp: 1;
  }

  .hp .downLinks .text-left {
    padding-top: 6% !important;
    /* border: red solid 1px; */
  }
}

@media screen and (max-width: 820px) {
  .hp .container {
    margin: 5% 0 !important;
    padding: 0 !important;
  }

  .hp .hp_banner_box,
  .hp .downLinks {
    margin: 0;
    padding: 0;
  }

  .hp .downLinks .imgBox img {
    height: 100%
  }

  .hp .hp_banner_left {
    width: 620px !important;
    height: 365px !important;
  }

  .hp .hp_banner_left .slider-container {
    width: 620px !important;
    height: 365px !important;
  }

  .hp .hp_banner_right {
    width: 620px;
    height: 180px !important;
  }

  .hp .newsTop,
  .hp .newsDown {
    width: 620px !important;
    height: 180px;
  }

  .hp .hp_banner_right .text-left {
    padding-top: 5%;
    font-size: 16px !important;
  }

  .hp .downLinks {
    width: 620px;
    height: 300px !important;
    padding: 0% 0% !important;
  }

  .hp .downLinks .row .col-4 {
    height: 150px !important;
  }

  .hp .downLinks .row .col-4 .text-left {
    font-size: 12px !important;
    line-height: 28px;
  }

  .top-left-carousel-text {
    font-size: 16px !important;
    line-height: 22px !important;
    bottom: 40px;
  }
}

@media screen and (max-width: 800px) {
  .top-left-carousel-text {
    font-size: 16px !important;
    /* line-height: 16px !important; */
    bottom: 40px;
  }

  .hp .container {
    margin-left: 0%;
  }

  .hp .hp_banner_box {
    margin-left: -6px;
  }

  .hp .hp_banner_left {
    width: 600px !important;
    height: 350px !important;
  }

  .hp .hp_banner_left .slider-container {
    width: 600px !important;
    height: 350px !important;
  }

  .hp .hp_banner_right {
    width: 600px;
    height: 175px !important;
  }

  .hp .newsTop,
  .hp .newsDown {
    width: 600px !important;
    height: 175px;
  }

  .hp .hp_banner_right .text-left {
    padding-top: 5%;
    font-size: 16px !important;
  }

  .hp .downLinks {
    width: 600px;
    height: 300px !important;
    padding: 0% 0% !important;
  }

  .hp .downLinks .row .col-4 {
    height: 150px !important;
  }

  .hp .downLinks .row .col-4 .text-left {
    font-size: 12px !important;
    line-height: 28px;
  }
}

@media screen and (max-width: 768px) {

  /* .homepage-container-div{
    z-index: -1 !important;
  } */
  .hp {
    margin-top: 5%;

  }

  .hp .slide-title h3 {
    background: red;
    font-size: 18px;
    width: 100%;
    height: 100%;
    font-weight: bold;


  }

  .hp .slide-title {
    position: absolute;
    top: 3%;
    right: 3% !important;
    width: max-content !important;
    height: 32px !important;
    background-color: #f73c29;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    color: white;
    z-index: 10;
  }

  .hp .container {
    padding: 0;
    width: 100% !important;
    margin-left: -1% !important;
  }

  .hp .downLinks .overlayTwo {
    height: 30%;
  }

  .hp .hp_banner_box {
    display: flex;
    margin: 0;
    padding: 0;
  }

  /* .hp .hp_banner_left,
  .hp .hp_banner_right {
    height: 300px;
  } */
  /* .hp .slider {
    height: 300px;
  } */

  /* .hp .downLinks {
    height: 300px;
    padding: 0% !important;
    transition: all ease-in-out 0.3s;
    display: flex;
    margin-left: 0;
  }
  .hp .downLinks .row {
    padding: 0 !important;
    margin: 0 !important;
  } */
  .hp .overlay {
    top: -15%;
    font-size: 8px;
  }

  .hp .overlayTwo {
    height: 30%;
    background: linear-gradient(to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.3) 25%,
        rgba(0, 0, 0, 0.9) 100%);
  }

  .hp .overlayTwo p {
    margin-top: 1%;
    font-size: 12px !important;
    line-height: 10px;
  }

  .hp .hp_banner_left {
    width: 100% !important;
    height: 335px !important;
  }

  .hp .hp_banner_left .slider-container {
    width: 100% !important;
    height: 335px !important;
  }

  .hp .hp_banner_right {
    width: 100%;
    height: 168px !important;
  }

  .hp .newsTop,
  .hp .newsDown {
    width: 100% !important;
    height: 168px;
  }

  .hp .hp_banner_right .text-left {
    padding-top: 5%;
    font-size: 16px !important;
  }

  .hp .downLinks {
    width: 100%;
    height: auto !important;
    padding: 0% 0% !important;
  }

  .hp .downLinks .row .col-4 {
    height: 168px !important;
    width: 50%;
  }

  .hp .downLinks .row .col-4 .text-left {
    font-size: 12px !important;
    line-height: 28px;
    position: relative;
    top: 3px;
  }

  /* .hp .prev,
  .hp .next {
    font-size: 30px;
  } */
}

@media screen and (max-width: 567px) {
  .top-left-carousel-text {
    font-size: 20px;
    padding-left: 10px;
    padding-right: 10px;

  }

  .page-button {
    margin: 0 3px;
    padding: 2px 0;
    width: 18px;
    font-size: 12px;
    color: white;
    font-weight: 700;
    border: none; 
}

  /* .hp .slider {
    height: 250px;
  } */

  .hp .slide {
    width: 100%;
    height: 100%;
  }

  .hp .downLinks .row {
    height: auto !important;
    padding: 0 !important;
    margin: 0 !important;
    justify-content: space-between;
  }

  .hp .downLinks {
    display: flex;
    margin-left: -1px;
  }

  .hp .number {
    width: 7px;
    height: 7px;
  }


  /* .hp .slide-content {
    height: 60px !important;
    padding-top: 2%;
    font-size: larger;
  } */
  .hp .overlay {
    top: -25%;
    font-size: 8px;
    padding: 1% 2%;
  }

  .hp .overlayTwo {
    height: 30%;
    background: linear-gradient(to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.3) 25%,
        rgba(0, 0, 0, 0.9) 100%);
    line-height: 8px;
  }

  .hp .overlayTwo p {
    font-size: 9px !important;
  }

  .hp .hp_banner_left {
    width: 100% !important;
    height: 300px !important;
  }

  .hp .hp_banner_left .slider-container {
    width: 100% !important;
    height: 300px !important;
  }

  .hp .hp_banner_right {
    width: 100%;
    height: 150px !important;
  }

  .hp .newsTop,
  .hp .newsDown {
    width: 100% !important;
    height: 150px;
  }

  .hp .hp_banner_right .text-left {
    padding-top: 5%;
    font-size: 16px !important;
  }

  .hp .downLinks {
    width: 100%;
    height: auto !important;
    padding: 0% 0% !important;
  }

  .hp .downLinks .row .col-4 {
    height: 150px !important;
  }

  .hp .downLinks .row .col-4 .text-left {
    font-size: 12px !important;
    line-height: 28px;
    
  }
}

@media (max-width: 480px) {
  .top-left-carousel-text {
    bottom: 40px;
  }

  .page-button {
    margin: 0 2px;
    padding: 2px 0;
    width: 16px;
    font-size: 10px;
    color: white;
    font-weight: 700;
    border: none; 
}

.slide-content {
  box-shadow: rgba(0, 0, 0, 0.85) 0px -200px 80px -100px inset !important;
}
}

@media (max-width: 431px) {

  .top-left-carousel-text {
    font-size: 14px !important;
    /* line-height: 14px !important; */
    bottom: 32px;
  }

  .hp .overlay {
    top: -20%;
    font-size: 9px;
    /* width: 70px; */
  }

  .category-badge {
    width: max-content;
    font-size: 11px;
  }

  .hp .downLinks {
    padding: 0 !important;
    height: 200px !important;
  }

  .hp .downLinks .imgBox {
    height: 100%;
  }

  .hp .overlayTwo {
    height: 40px;
    line-height: 9px;
    /* border: solid 2px red; */
  }

  .hp .hp_banner_right .overlayTwo {
    padding: 2% 0;
  }

  .page-button {
    margin: 0 2px;
    padding: 2px 0;
    width: 14px;
    font-size: 10px;
    color: white;
    font-weight: 700;
    border: none; 
}

  .hp .hp_banner_right .overlayTwo p {
    line-height: 22px !important;
    font-size: 10px !important;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    /* Gösterilecek maksimum satır sayısı */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .hp .downLinks .overlayTwo {
    height: 35px;
  }

  /* 
      .hp .downLinks .overlayTwo p {
        font-size: 12px !important;
      } */
  /* .hp .slider .text-left {
    line-height: 20px !important;
  } */
}

@media screen and (max-width: 430px) {
  .hp p.text-left {
    margin-top: 2% !important;
  }

  .hp .hp_banner_left {
    width: 100% !important;
    height: 225px !important;
  }

  .hp .hp_banner_left .slider-container {
    width: 100% !important;
    height: 225px !important;
  }

  .hp .hp_banner_right {
    width: 100%;
    height: 112px !important;
  }

  .hp .newsTop,
  .hp .newsDown {
    width: 100% !important;
    height: 112px;
  }

  .hp .hp_banner_right .text-left {
    padding-top: 5%;
    font-size: 16px !important;
  }

  .hp .downLinks {
    width: 100%;
    height: auto !important;
    padding: 0% 0% !important;
  }

  .hp .downLinks .row .col-4 {
    height: 100px !important;
    
  }

  .hp .downLinks .row .col-4 .text-left {
    font-size: 10px !important;
    line-height: 28px;
    top: -4px;
  }
}

@media (max-width: 390px) {
  .top-left-carousel-text {
    font-size: 14px !important;
    line-height: 22px !important;

  }

  .page-button {
    margin: 0 1.5px;
    padding: 1px 0;
    width: 14px;
    font-size: 10px;
    color: white;
    font-weight: 600;
    border: none; 
}

  .hp .downLinks .overlayTwo {
    height: 30px;
  }

  .hp .downLinks .overlayTwo p {
    font-size: 10px !important;
  }

  /* .hp .slide-content{
        height: 40px;
        margin-top: -5% !;
        overflow: hidden;
      } */
  /* .hp .slide-content span{
        display: -webkit-box;
        -webkit-line-clamp: 2; 

      } */
  /* .hp .text-left{
        font-size: 16px !important;
         } */
  .hp .hp_banner_left {
    width: 350px !important;
    height: 205px !important;
  }

  .hp .hp_banner_left .slider-container {
    width: 350px !important;
    height: 205px !important;
  }

  .hp .hp_banner_right {
    width: 350px;
    height: 102px !important;
  }

  .hp .newsTop,
  .hp .newsDown {
    width: 350px !important;
    height: 102px;
  }

  .hp .hp_banner_right .text-left {
    padding-top: 5%;
    font-size: 16px !important;
  }

  .hp .downLinks {
    width: 350px;
    height: 305px !important;
    padding: 0% 0% !important;
  }

  .hp .downLinks .row .col-4 {
    height: 100px !important;
    width: 50%;
  }

  .hp .downLinks .row .col-4 .text-left {
    font-size: 10px !important;
    line-height: 28px;
    position: relative;
    top: -7px;
  }
}

@media (max-width:375px) {
  .hp {
    /* border: solid red; */
    margin-left: 6%;
  }

  .page-button {
    margin: 0 1.2px;
    padding: 1px 0;
    width: 13px;
    font-size: 10px;
    color: white;
    font-weight: 600;
    border: none; 
}

  .hp .text-left {
    font-size: 12px !important;
  }

  .hp .hp_banner_left {
    width: 325px !important;
    height: 195px !important;
  }

  .hp .hp_banner_left .slider-container {
    width: 325px !important;
    height: 195px !important;
  }

  .hp .hp_banner_right {
    width: 325px;
    height: 97px !important;
  }

  .hp .newsTop,
  .hp .newsDown {
    width: 325px !important;
    height: 97px;
  }

  .hp .hp_banner_right .text-left {
    padding-top: 5%;
    font-size: 12px !important;
  }

  .hp .downLinks {
    width: 325px;
    height: auto !important;
    padding: 0% 0% !important;
  }

  .hp .downLinks .row .col-4 {
    height: 95px !important;
  }

  .hp .downLinks .row .col-4 .text-left {
    font-size: 10px !important;
    line-height: 25px !important;
  }

}

@media (max-width:360px) {


  .hp .text-left {
    font-size: 12px !important;
  }

  .hp .hp_banner_left {
    width: 330px !important;
    height: 195px !important;
  }

  .hp .hp_banner_left .slider-container {
    width: 330px !important;
    height: 195px !important;
  }

  .hp .hp_banner_right {
    width: 330px;
    height: 97px !important;
  }

  .hp .newsTop,
  .hp .newsDown {
    width: 330px !important;
    height: 97px;
  }

  .hp .hp_banner_right .text-left {
    padding-top: 5%;
    font-size: 12px !important;
  }

  .hp .downLinks {
    width: 330px;
    height: auto !important;
    padding: 0% 0% !important;
  }

  .hp .downLinks .row .col-4 {
    height: 90px !important;
  }

  .hp .downLinks .row .col-4 .text-left {
    font-size: 10px !important;
    line-height: 25px !important;
  }

}