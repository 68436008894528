.parite .container {
    margin-top: 2%;
    margin-bottom: 15%;
  }
  
  .parite .th-style {
    background: #2c98f0;
    color: white;
  }
  
  .parite th,
  .parite td {
    border: solid 1px white;
  }
  
  .parite .col-md-12 button {
    margin-left: -12px;
    margin-right: 10px;
  }
  
  .parite .col-md-12 button:hover {
    background-color: #0d6efd;
  }
  
  .parite .btnbaslik {
    background-color: #f5f5f5;
  }
  
  .parite .circle {
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #fed642;
    margin-right: 8px;
  }
  
  .parite .pariteTitle {
    border-top: solid 2px rgb(248, 113, 113);
    background-color: #f2f2f2;
    margin-bottom: 0;
    padding: 1%;
  }
  
  @media screen and (max-width: 1980px) {
    .parite .container {
      margin-top: 32px;
      margin-bottom: 4%;
      padding-left: 2% !important;
      padding-right: 2% !important;
    }
  }
  
  @media screen and (max-width: 1920px) {
    .parite .container {
      margin-top: 27px;
      margin-bottom: 9%;
      padding-left: 15% !important;
      padding-right: 15% !important;
    }
  }
  
  @media screen and (max-width: 1512px) {
    .parite .container {
      margin-top: 28px;
      padding-left: 9% !important;
      padding-right: 9% !important;
    }
  }
  
  @media screen and (max-width: 1280px) {
    .parite .container {
      margin-top: 22px;
      padding-left: 17% !important;
      padding-right: 17% !important;
    }
  }
  
  @media screen and (max-width: 1200px) {
    .parite .container {
      margin-top: 22px;
      padding-left: 7% !important;
      padding-right: 7% !important;
    }
  }
  
  @media screen and (max-width: 1024px) {
    .parite .container {
      margin-top: 18px;
      padding-left: 14% !important;
      padding-right: 14% !important;
    }
  }
  
  @media screen and (max-width: 991px) {
    .parite .container {
      margin-top: 15px !important;
      padding-left: 10% !important;
      padding-right: 10% !important;
    }
  
    .parite thead tr th,
    .parite tbody tr td {
      font-size: 12px;
    }
  }
  
  @media screen and (max-width: 835px) {
    .parite .container {
      margin-top: 15px !important;
      padding-left: 18% !important;
      padding-right: 18% !important;
    }
  
    .parite button {
      font-size: 13px;
    }
  
    .parite thead tr th,
    .parite tbody tr td {
      font-size: 10px;
    }
  }
  
  @media screen and (max-width: 800px) {
    .parite .container {
      margin-top: 13px !important;
      padding-left: 21% !important;
      padding-right: 21% !important;
    }
  
    .parite button {
      font-size: 12px;
    }
  
    .parite thead tr th,
    .parite tbody tr th {
      font-size: 10px;
    }
  }
  
  @media screen and (max-width: 768px) {
    .parite .container {
      margin-top: 13px !important;
      padding-left: 6% !important;
      padding-right: 5% !important;
    }
  
    .parite button {
      font-size: 13px;
    }
  
    .parite thead tr th,
    .parite tbody tr th {
      font-size: 12px;
    }
  }
  
  @media screen and (max-width: 430px) {
    .parite .container {
      padding-left: 2% !important;
      padding-right: 1% !important;
    }
  
    .parite button {
      font-size: 11px;
    }
  
    .parite thead tr th,
    .parite tbody tr th {
      font-size: 10px;
    }
  }
  