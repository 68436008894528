.content-page-header {
  text-align: start;
}

.content-header-text {
  color: #d4d4d4;
  margin-top: 10px;
}

.content-title1 {
  font-size: 32px;
  color: #ffffff;
  font-weight: 600;
}

.content-container {
  border-top: 1px solid rgb(209, 209, 209);
}

.content-left-container {
  border-right: 1px solid rgb(209, 209, 209);
  padding-top: 30px;
  padding-bottom: 60px;
  text-align: start;
}

.content-right-container {
  padding-top: 30px;
  padding-bottom: 60px;
  text-align: start;
}

.content-image {
  margin-bottom: 32px;
  width: auto;
  max-height: 650px;
  min-height: 500px;
}

.share-link-container>a {
  height: 35px;
  width: 35px;
  font-size: 16px;
  border: 2px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  transition: all 0.15s;
}

.content-text {
  font-size: 14px;
  margin-bottom: 10px;
}

.news-content-text>p>a {
  color: #0066cc;
}

.news-content-text>p>a:hover {
  color: #00478d;
}

.news-content-text iframe {
  width: 100%;
  height: 492px;
}

.content-title2 {
  font-size: 28px;
  font-weight: 600;
  margin-top: 30px;
  margin-bottom: 15px;
}

.content-author-image {
  width: 90px;
  height: 90px;
  border-radius: 100%;
}

@media (max-width: 2560px) {
  .news-content-text iframe {
    width: 100%;
    height: 725px;
  }
}

@media (max-width: 1920px) {
  .news-content-text iframe {
    width: 100%;
    height: 492px;
  }
}

@media (max-width: 1920px) {
  .content-page-container .news-item img {
    height: 160px;
  }

  .content-page-container .news-item h3 {
    /* border: solid red 1px; */
    margin-bottom: 0px;
    font-size: 16px;
  }
}

@media (max-width: 1800px) {
  .content-image {
    max-height: 550px;
    min-height: 350px;
  }

  .news-content-text iframe {
    width: 100%;
    height: 455px;
  }
}

@media (max-width: 1600px) {
  .content-image {
    max-height: 550px;
    min-height: 350px;
  }

  .news-content-text iframe {
    width: 100%;
    height: 383px;
  }
}

@media (max-width: 1512px) {
  .content-page-container .news-item img {
    height: 115px;
  }
}

@media (max-width: 1440px) {
  .content-image {
    max-height: 600px;
    min-height: unset;
  }

  .content-page-container .news-item img {
    height: 105px;
  }

  .news-content-text iframe {
    width: 100%;
    height: 325px;
  }

  .page-link-container,
  .content-page-header,
  .content-container {
    padding-left: 200px;
    padding-right: 200px;
  }
}

@media (max-width: 1365px) {
  .content-page-container .news-item img {
    height: 95px;
  }
}

@media (max-width: 1280px) {

  .page-link-container,
  .content-page-header,
  .content-container {
    padding-left: 180px;
    padding-right: 180px;
  }

  .content-page-container .news-item img {
    height: 160px;
  }

  .content-page-container .news-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .news-content-text iframe {
    width: 100%;
    height: 268px;
  }

}

@media (max-width: 1024px) {

  .content-page-container .news-item img {
    height: 130px;
  }

  .news-content-text iframe {
    width: 100%;
    height: 241px;
  }
}

@media (max-width: 991px) {
  .content-page-container .news-item img {
    height: 120px;
  }
}

@media (max-width: 820px) {

  .page-link-container,
  .content-page-header,
  .content-container {
    padding-left: 80px;
    padding-right: 80px;
  }

  .content-container {
    grid: unset;
    grid-column: unset;
  }

  .content-left-container {
    border: none;
    padding-right: 0;
  }

  .content-right-container {
    padding-left: 0;
    grid-column: span 11 / span 11;
  }

  .content-page-container .news-item img {
    height: 180px;
  }

  .news-content-text iframe {
    width: 100%;
    height: 371px;
  }
}

@media (max-width: 768px) {
  .content-container {
    padding-left: 15px;
    padding-right: 15px;
    border: none;
  }

  .content-left-container {
    border: none;
  }

  .content-right-container {
    display: none;
  }

  .page-link-container {
    padding-left: 15px;
    padding-right: 15px;
  }

  .content-page-header {
    padding-left: 15px;
    padding-right: 15px;
  }

  .share-link-container>a {
    height: 22px;
    width: 22px;
    font-size: 12px;
    border-width: 1px;
  }

  .next-previous-content {
    margin-left: 6px;
    margin-right: 6px;
    font-size: 15px;
  }

  .news-content-text iframe {
    width: 100%;
    height: 415px;
  }
}

@media (max-width: 567px) {
  .content-page-container .news-item img {
    height: 150px;
  }
}

@media (max-width: 430px) {
  .content-page-container .news-item img {
    height: 180px;
  }

  .content-page-container .news-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (max-width: 390px) {
  .content-page-container .news-item img {
    height: 160px;
  }
}

@media (max-width: 360px) {
  .content-page-container .news-item img {
    height: 140px;
  }
}

@media (max-width: 567px) {
  .news-content-text iframe {
    width: 100%;
    height: 302px;
  }
}

@media (max-width: 480px) {
  .news-content-text iframe {
    width: 100%;
    height: 253px;
  }
}

@media (max-width: 393px) {
  .news-content-text iframe {
    width: 100%;
    height: 204px;
  }
}

@media (max-width: 320px) {
  .news-content-text iframe {
    width: 100%;
    height: 163px;
  }
}