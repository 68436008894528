.category-page-slider-card {
  width: 100%;
  /* max-width: 445px; */
  height: auto;
}

.category-page-slider-main-card {
  position: relative;
  text-align: center;
  color: white;
  width: 100%;
}

.category-page-main-card-image {
  width: 100%;
  height: 200px;
  border-radius: 5px;
}

.category-page-category-slider-btn {
  position: absolute;
  top: 3px;
  left: 3px;
  background-color: #E96F25;
  color: white;
  border: none;
  padding: 3px 7px;
  margin: 3px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 15px;
  font-weight: 600;
}

.category-page-title1 {
  width: 100%;
  color: rgb(31 41 55);
  font-weight: 700;
  font-size: 18px;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-bottom: 10px;
  text-align: center;
  background-color: #f7f7f7;
  border-top: 3px solid rgb(248 113 113);
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}

.category-page-main-card-text {
  position: absolute;
  bottom: 0px;
  left: 0px;
  /* width: 100%; */
  height: 3em;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 5px;
  text-align: left;
  height: 3em;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  white-space: normal;
  text-overflow: ellipsis;
}

.category-page-sub-cards {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
  transition: opacity 0.5s ease-in-out;
}

.fade-in {
  opacity: 1;
}

.fade-out {
  opacity: 0;
}

.category-page-sub-card {
  display: flex;
  align-items: center;
  gap: 10px;
  transition: transform 0.3s ease;
}

.category-page-sub-card:hover {
  transform: scale(1.05);
}

.category-page-sub-card-image {
  width: 120px;
  height: 90px;
  object-fit: cover;
  border-radius: 5px;
}

.category-page-sub-card-text {
  flex: 1;
  text-align: left;
  font-size: 15px;
}

.category-page-slider-card .navigation {
  display: flex;
  margin: 15px 7px 20px;
  line-height: 1;
}

.category-page-slider-card .navigation>span {
  padding: 8px 12px;
  display: inline-block;
  margin-right: 3px;
  cursor: pointer;
  transition: background-color 300ms, color 300ms;
  -moz-transition: background-color 300ms, color 300ms;
  -o-transition: background-color 300ms, color 300ms;
  -webkit-transition: background-color 300ms, color 300ms;
  background-color: #e9e9e9;
}

.category-page-slider-card .navigation>span:hover {
  background-color: gray;
}

.category-page-slider-card .navigation>span:hover i {
  color: #ffff;
}

@media (max-width: 375px) {
  .category-page-slider-main-card {
    width: 112% !important;
  }
}

@media (max-width: 820px) {
  .category-page-main-card-image {
    
    height: auto;
    
  }
}

@media (max-width: 768px) {
  .category-page-slider-card {
    margin-top: 30px;
    /* width: 298px; */
    height: auto;
    margin-left: 0 !important;
  }

  .category-page-slider-cards-content {
    flex-direction: column !important;
  }

  .navigation {
    margin: 15px 7px 20px 0;
  }

  /* .category-page-slider-main-card {
    width: 130%;
  } */
}