.main-card {
  
  font-family: Arial, sans-serif;
  padding: 0px;
  border-radius: 8px;
}

.image-container {
  position: relative;
  text-align: center;
  color: white;
  height: 300px;
  width: 100%;
}

.image-container img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 8px;
}

.category {
  position: absolute;
  top: 10px;
  left: 10px;
  font-family: unset;
}

.category-btn {
  background-color: #00aaff;
  color: white;
  border: none;
  padding: 3px 7px;
  margin: 3px;
  cursor: pointer;
  border-radius: 7px;
  font-size: 14px;
}

.image-container h2 {
  position: absolute;
  bottom: 10px;
  left: 15px;
  font-size: 20px;
  margin: 0;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 7px;
  border-radius: 4px;
}

.image-container p {
  position: absolute;
  bottom: 15px;
  left: 15px;
  margin: 0;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 3px;
  border-radius: 4px;
  font-size: 12px;
}

.sub-cards {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 15px;
}

.sub-card {
  display: flex;
  padding: 5px;
  cursor: pointer;
  border-radius: 4px;
  /* border: solid red; */
}

.sub-card img {
  width: 180px;
  height: 90px;
  object-fit: cover;
  margin-right: 8px;
  border-radius: 4px;
  /* border: solid red; */

}

.sub-card-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.sub-card-text h3 {
  margin: 0 0 3px 0;
  font-size: 16px;
}
@media (max-width:1280px){
  .sub-card img {
    width: 140px;
    height: 70px;
    object-fit: cover;
    margin-right: 8px;
    border-radius: 4px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2; 
    -webkit-box-orient: vertical;

  }
  
  .sub-card-text {
    font-size: 14px !important;
  }
  
.sub-card-text h3 {
  font-size: 14px;
}
}
@media (max-width:1024px) {
  
  .sub-card-text {
    font-size: 11px !important;
  }
  
.sub-card-text h3 {
  font-size: 11px;
}
}
@media (max-width:820px) {
  
  .sub-card-text {
    font-size: 12px !important;
  }
  
.sub-card-text h3 {
  font-size: 12px;
}
}
@media (max-width: 768px) {
  .main-card {
    max-width: 100%;
    /* padding: 0 10px; */
    margin-bottom: 20px;
    padding-top: 1.5rem;
  }

  .image-container {
    height: 250px;
  }

  .image-container img{
    height: 250px;
  }

  .sub-card {
    padding: 1px;
  }


  .sub-card img {
    width: 100px;
    height: 50px;
    margin-right: 8px;
  }
  .sub-card-text{
    font-size: 14px !important;

  }
  .sub-card-text h3 {
    font-size: 14px !important;
  }

  .sub-card-text p {
    font-size: 14px !important;
  }
}

@media (max-width: 480px) {
  .image-container {
    /* height: 200px; */
  }

  .category-btn {
    font-size: 12px;
    padding: 2px 5px;
  }

  h2 {
    font-size: 16px;
    padding: 5px;
  }

  .image-container p {
    font-size: 10px;
    padding: 2px;
  }

  .sub-card img {
    width: 120px;
    height: 60px;
  }

  .sub-card-text h3 {
    font-size: 14px;
  }

  .sub-card-text p {
    font-size: 9px;
  }
}
@media (max-width:360px){

  .sub-card img {
    width: 100px;
    height: 50px;
  }
  
  .sub-card-text h3 {
    font-size: 12px !important;
  }

  .sub-card-text {
    font-size: 12px !important;
  }
}