.livetv-page-container{
    padding-top: 30px;
  padding-bottom: 250px;
}

.livetv-mid-container{
  background-color: rgb(232, 232, 232);
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  height: 600px;
  padding: 20px;
}

.livetv-page-title1 {
    width: 100%;
    color: rgb(31 41 55);
    font-weight: 700;
    font-size: 40px;
    padding-bottom: 8px;
    margin-bottom: 15px;
    text-align: start;
  }

  .livetv-page-title2 {
    width: 100%;
    color: rgb(31 41 55);
    font-weight: 700;
    font-size: 20px;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-bottom: 15px;
    text-align: center;
    background-color: #f7f7f7;
    border-top: 3px solid rgb(168, 168, 168);
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    border-radius: 10px 10px 0 0;
  }

  .livetv-mid-container{
    width: 100%;
  }

  .livetv-mid-left-container{
    /* border: 1px solid blue; */
    background-color: white;
    border-radius: 10px;
    
  }
  .livetv-mid-right-container{
    /* border: 1px solid red; */
    background-color: white;
    border-radius: 10px;
  }

  .livetv-tabs{
    flex-direction: column !important;
    flex-wrap: nowrap;
    gap: 2px !important;
    max-height: 435px !important;
    overflow-y: auto;
    padding-left: 30px;
  }

  .channel-logo-img{
width: 50px;
  }


  @media (max-width: 1024px) {
    .livetv-page-container {
      padding-left: 150px;
      padding-right: 150px;
      padding-bottom: 50px;
    }

    .channel-logo-img{
      width: 30px;
    }

    .livetv-tabs{padding-left: 5px;}
  }
  
  @media (max-width: 820px) {
    .livetv-page-container {
      padding-left: 80px;
      padding-right: 80px;
      padding-bottom: 50px;
    }

    .livetv-page-title1{
        font-size: 28px;
    }

    .livetv-tabs{padding-left: 20px;}

    .livetv-tabs{
      max-height: 200px !important;}

      .livetv-mid-right-container{
        margin-top: 10px;
      }

      .livetv-mid-container{
        height: auto;
      }

      .livetv-iframe{ 
        height: auto;
        width: auto;
      }
  
    
  }
  
  @media (max-width: 768px) {
    .livetv-page-container {
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 50px;
    }
  
  }

