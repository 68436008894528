.adsmidtop-container {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  margin-bottom: 50px;
}

.adsmidtop-ad-wrapper {
  flex: 1;
  max-width: calc(33.33% - 7px);
  text-align: center;
}

.adsmidtop-ad-wrapper a {
  display: flex;
  justify-content: center;
}

.adsmidtop-ad-container {
  width: 80%;
  padding-top: 80%; /* Aspect ratio 1:1 için (kare) */
  position: relative;
  overflow: hidden;
}

.adsmidtop-ad-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.adsmidtop-ad-content img,
.adsmidtop-ad-content iframe {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: none;
}

/* Mobil cihazlar için medya sorgusu */
@media (max-width: 768px) {
  .adsmidtop-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px; /* Mobilde reklamlar arası boşluğu artırıyoruz */
  }

  .adsmidtop-ad-wrapper a {
    display: unset;
  }

  .adsmidtop-ad-wrapper {
    max-width: 50%;
    width: 50%;
  }

  .adsmidtop-ad-container {
    width: 100%;
    padding-top: 100%; /* Mobilde tam kare yapalım */
  }
  
  .adsmidtop-ad-content img,
  .adsmidtop-ad-content iframe {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: none;
  }
  @media (max-width:430px) {
    
  .adsmidtop-container div iframe{
      width: 300px;
      height: 300px;
  }  
 

  }
}