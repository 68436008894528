/* Temel yapılar ve container stilleri */
.tablet-ads-container {
    display: none;
    position: relative;
    width: 75.5%;
    height: 160px;
    overflow: hidden;
    margin-left: 13%;
    margin-top: 1%;
    margin-bottom: -3%;
}

.tablet-ads-container .slider {
    position: relative;
    width: 100%;
    height: auto;
}

/* Slider container ve geçiş efektleri */
.tablet-ads-container .slider-container {
    display: flex;
    transition: transform 1s ease-in-out; 
    width: 100%; 
}

/* Her bir slide */
.tablet-ads-container .slide {
    min-width: 100%;
    box-sizing: border-box;
}

/* Resim stilleri */
.tablet-ads-container .slideImg img {
    width: 100%;
    height: 130px;
    display: none;
}

#canvas {
    height: 140px !important;
}

#animation_container {
    height: 140px !important;
}

#dom_overlay_container {
    height: 140px !important;
}

@media (max-width:820px) {
    .tablet-ads-container {
        display: flex;
    }
    .tablet-ads-container .slideImg img {
        display: block;
        margin-top: 55px;
    }
}

@media (max-width:800px) {
    .tablet-ads-container {
        width: 74.5%;
        display: flex;
        justify-content: center;
        margin-bottom: -3%;
    }
}

@media (max-width:768px) {
    .tablet-ads-container {
        width: 75%;
        display: flex;
        justify-content: center;
        margin-bottom: -3%;
    }
}

@media (max-width:567px) {
    .tablet-ads-container {
        display: none;
    }
}

@media (max-width:430px) {
    .tablet-ads-container {
        display: none;
    }
    .tablet-ads-container {
        width: 89%;
        height: 130px;
        margin-left: 6.5%;
    }
    .tablet-ads-container .slideImg img {
        height: 120px;
    }
}

@media (max-width:360px) {
    .tablet-ads-container {
        margin-left: 23px;
        height: 130px;
    }
}
