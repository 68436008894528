.prayer-page-container {
  padding-top: 30px;
  padding-bottom: 250px;
}

.prayer-page-div {
  background-color: #038692;
  color: white;
  border-radius: 6px;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  background-image: url("/src/components/namaz/mosque.png");
  background-size: cover;
  background-position: center;
  height: 100%;
}

.prayer-page-input {
  background-color: #03575e;
  border: none !important;
  height: auto;
  width: 280px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 28px;
  font-weight: 600;
  margin-top: 8px;
  margin-bottom: 18px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 5px;
  padding-top: 5px;
  border-radius: 6px;
}

.prayer-page-input:hover {
  background-color: #02383c;
}

.prayer-page-input:focus {
  background-color: #02383c;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  /* outline: 2px solid black; */
  outline: none;
}

.prayer-page-dropdown-menu {
  height: 300px;
  max-height: 300px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  overflow: auto;
  z-index: 900 !important;
  background-color: #03575e;
  color: white !important;
}

.prayer-page-dropdown-item {
  color: white !important;
  font-size: 18px;
  font-weight: 600;
}

.prayer-page-dropdown-item:hover {
  background-color: #02383c;
}

.prayer-times-list {
  background-color: #03575e;
  border-radius: 6px;
  width: 280px;
}

.prayer-times-list>li {
  padding: 8px;
}

.next-prayer {
  background-color: #012124
}

@media (max-width: 1024px) {
  .prayer-page-container {
    padding-left: 150px;
    padding-right: 150px;
  }
}

@media (max-width: 820px) {
  .prayer-page-container {
    padding-left: 80px;
    padding-right: 80px;
  }

}

@media (max-width: 768px) {
  .prayer-page-container {
    padding-left: 15px;
    padding-right: 15px;
  }

}