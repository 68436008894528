.popUpAds {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: auto;
  background-color: #fff;
  transition: transform 0.5s ease-in-out;
  transform: translateY(100%);
  z-index: 999;
  box-shadow: rgba(50, 50, 93, 0.15) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  border-top: 8px solid rgb(234, 234, 234);
  
}

.popUpAds.open {
  transform: translateY(0);
}

.popUpAds.closed {
  transform: translateY(100%);
}

.popUpAds .adContent {
  width: 100%;
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.popUpAds .adContent.visible {
  opacity: 1;
}

.popUpAds .adContent.hidden {
  opacity: 0;
}


.popUpAds .toggleButton {
  position: absolute;
  left: 10px;
  top: -38px;
  background-color: rgb(234, 234, 234);
  color: #C90913;
  font-weight: bold;
  border: none;
  border-radius: 10px 10px 0 0;
  padding: 3px 20px;
  cursor: pointer;
}

.popUpAds .adContent {
  width: 100%;
  display: flex;
  justify-content: center;
}

.popUpAds .adContent img {
  width: 80%;
  height: auto;
  object-fit: cover;
}

.adsPopupCenter .close-button{
  position: absolute;
  background-color: #1f2937;
  padding: 1% 2%;
  right: 8px;
  top: 8px;
  border-radius: 100%;
  color: #fff;
  opacity: 0.7;
  transition: all ease-in-out 0.3s;
}

.adsPopupCenter .close-button:hover{
opacity: 1;
}

@media screen and (max-width: 1024px) {
  .popUpAds .adContent img {
    width: 100%;
  }
}
@media screen and (max-width: 991px) {
  .popUpAds .adContent{
    width: 991px !important;
  }
  .popUpAds .adContent img {
    width: 100% !important;
  }
}  @media screen and (max-width: 835px) {
  .popUpAds .adContent{
    width: 835px !important;
  }
  .popUpAds .adContent img {
    width: 100% !important;
  }
}  @media screen and (max-width: 820px) {
  .popUpAds .adContent{
    width: 820px !important;
  }
  .popUpAds .adContent img {
    width: 100% !important;
  }
}  @media screen and (max-width: 800px) {
  .popUpAds .adContent{
    width: 800px !important;
  }
  .popUpAds .adContent img {
    width: 100% !important;
  }
}  @media screen and (max-width: 768px) {
  .popUpAds .adContent{
    width: 768px !important;
  }
  .popUpAds .adContent img {
    width: 100% !important;
  }
}
@media screen and (max-width: 576px) {
  .popUpAds .adContent{
    width: 576px !important;
  }
  .popUpAds .adContent img {
    width: 100% !important;
  }
}
@media screen and (max-width: 430px) {
  .popUpAds .adContent{
    width: 430px !important;
  }
  .popUpAds .adContent img {
    width: 100% !important;
  }
}



.adsPopupCenter {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0, 0, 0, 0.5); */
  z-index: 1050;
}

.adsPopupCenter.active {
  display: block;
}

.modal-dialog-centered {
margin-top: 130px;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.close-button {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
  background: none;
  border: none;
  cursor: pointer;
}

.close-button:hover {
  opacity: .75;
}