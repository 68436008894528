.trends {
  /* display: flex; */
  flex-direction: column;
  align-items: flex-end;
  margin-right: -1px;
}

.more {
  display: flex;
  justify-content: end;
}

.trending {
  font-family: Arial, sans-serif;
}

.trending ul {
  list-style: none;
  padding: 0;
  text-align: left;
  margin-top: 10px;
}

.trending li {
  
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 5px;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
}

.trending .index {
  font-weight: bold;
  margin-right: 0.5em;
}

.trending .text {
  flex-grow: 1;
}

.trending .arrow {
  color: red;
  font-size: 8px;
}

.pop-news {
  font-size: 23px;
  font-family: Arial, sans-serif;
  font-weight: bold;
}
.adsxl{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ads-trends {
  margin-top: -22px !important;

}
/* .ads-trends img {
  
  width: 90%;
  margin: 0px auto;
} */
.midbtnads2 {
  display: none;
}

.ad-containerx{
  width: 80%;
  margin: 0 auto;
}

.league-table-ads{
  width: 90%;
  margin: 0px auto;
}

.time-frame-buttons{
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 15px;
}

.time-frame-buttons button {
  background-color: #F87171;
  color: white;
  padding: 5px 10px;
  border-radius: 10px;
  transition: all 0.3s;
}

.time-frame-buttons button:focus{
  background-color: #a04343;
}

.trend-news-item{
  transition: all 0.2s;
}

.trend-news-item:hover{
  background-color: rgb(241, 241, 241);
}

.text.trend-news-title {
  white-space: nowrap;      /* Metni tek satırda tutar */
  overflow: hidden;         /* Taşan kısmı gizler */
  text-overflow: ellipsis;  /* Taşan metnin yerine üç nokta (...) koyar */
  display: block;           /* Satır içi elementleri engellemek için block yapabiliriz */
  width: 100%;              /* Elemanın bulunduğu konteynerin genişliğini alır */
  max-width: 100%;          /* Gereksiz genişlemeyi engeller */
}

@media (max-width: 1920px) {

  .ads-trends {
    margin-top: 15px !important;
  }
}
@media (max-width: 1512px) {

  /* .ads-trends img {
    width: 100%;
    width: 300px;
    height: 300px;
  } */
  .ads-trends {
    margin-top: -15px !important;
    margin-bottom: -15px;
  }
}
@media (max-width: 1440px) {

  /* .ads-trends img {
    width: 100%;
    max-width: 300px;
    max-height: 300px;
  } */
  .ads-trends {
    margin-top: 15px !important;
    margin-bottom: 0;
  }
}
@media (max-width: 1280px) {

  .ads-trends {
    margin-top: 20px !important;
  }

}
@media (max-width: 1200px) {

}
@media (max-width: 1024px) {

  .ads-trends {
    margin-top: 20px !important;
    /* margin-bottom: 40px !important; */
  }
  .ad-containerx div iframe{
    width: auto;
    height: auto;
    /* margin-left: -2% !important; */
  }
}
@media (max-width: 991px) {
  .ads-trends {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: 20px;
  }
  .midbtnads2 {
    display: block;
  }
  /* .ads-trends img {
    width: 100%;
    max-width: 300px;
    max-height: 300px;
  } */
  .ad-containerx div iframe{
    margin-left: 27% !important;
  }
}

@media (max-width: 821px) {
.league-table-ads{
    height: 300px;   
    width: 300px; 
    margin: 0px auto;
  }
  .ad-containerx div iframe{
    margin-left: 21% !important;
  }
}


@media (max-width: 768px) {
  .trends {
    align-items: center;
    margin-top: -15px;
    margin-right: 0;
  }

  .trending {
    /* width: 100%; */
    margin-top: 30px;
  }

  .ads-trends {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: 10px;
  }
  /* .ads-trends img {
    width: 280px !important;
    height: 280px !important;
  } */

  .ad-containerx div img{
    width: 300px;
    height: 300px;
  }

  .ad-containerx div{
    width: 300px;
    height: 300px;
  }
  .ad-containerx div iframe{
    margin-left: 0px !important;
  }

  .ad-containerx {
    width: 300px;
    height: 300px;
  }

  

  .pop-news {
    font-size: 20px;
  }

  .trending li {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .pop-news {
    font-size: 18px;
  }

  .trending li {
    font-size: 14px;
    padding: 0.5em 0;
  }

  /* .ads-trends img {
    max-width: 100%;
  } */
}
@media (max-width: 430px) {
  /* .ads-trends img {
    width: 300px !important;
    height: 300px !important;
  } */
}
.trends .league-table {
  font-family: Arial, sans-serif;
  /* max-width: 410px; */
  /* padding: 15px; */
  margin: 0 auto;
  /* background-color: #fff; */
  border-radius: 8px;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.05); */
  /* margin-top: 20px; */
}



.trends h2 {
  margin-top: 0;
  font-size: 18px;
}

.trends table {
  width: 100%;
  border-collapse: collapse;
}

.trends th,
td {
  padding: 10px 5px;
  text-align: left;
}

.trends th {
  font-weight: normal;
  color: #777;
}

.trends tr {
  border-bottom: 1px solid #eee;
}

.trends tr:last-child {
  border-bottom: none;
}

.trends .expand {
  text-align: center;
  color: green;
  padding: 10px 0;
  cursor: pointer;
}

.trends .view-table {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: green;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
}
