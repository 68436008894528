.kurFyt .container {
  margin-top: 2%;
  margin-bottom: 4%;
}

.kurFyt .th-style {
  background: #2c98f0;
  color: white;
}

.kurFyt th,
.kurFyt td {
  border: solid 1px white;
}

.kurFyt .col-md-12 button {
  margin-left: -12px;
  margin-right: 10px;
}

.kurFyt .col-md-12 button:hover {
  background-color: #0d6efd;
}

.kurFyt .btnbaslik {
  background-color: #f5f5f5;
}

.kurFyt .circle {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: solid 1px;
  margin-right: 8px; 
  background-size: cover;
  background-position: center;
}
.kurFyt .text-success {
  color: green;
}

.kurFyt .text-danger {
  color: red;
}
.kurFyt .fontK {
  font-weight: 600;
}
.kurFyt .dovizTitle {
  border-top: solid 2px rgb(248, 113, 113);
  background-color: #f2f2f2;
  margin-bottom: 0;
  padding: 1%;
}
@media screen and (max-width: 1980px) {
  .kurFyt .container {
    margin-top: 32px;
    margin-bottom: 4%;
    padding-left: 2% !important;
    padding-right: 2% !important;
  }
}
@media screen and (max-width: 1920px) {
  .kurFyt .container {
    margin-top: 24px;
    margin-bottom: 4%;
    padding-left: 15% !important;
    padding-right: 15% !important;
  }
}

@media screen and (max-width: 1512px) {
  .kurFyt .container {
    margin-top: 28px;
    padding-left: 9% !important;
    padding-right: 9% !important;
  }
}

@media screen and (max-width: 1280px) {
  .kurFyt .container {
    margin-top: 22px;
    padding-left: 17% !important;
    padding-right: 17% !important;
  }
}

@media screen and (max-width: 1200px) {
  .kurFyt .container {
    margin-top: 22px;
    padding-left: 7% !important;
    padding-right: 7% !important;
  }
}

@media screen and (max-width: 1024px) {
  .kurFyt .container {
    margin-top: 18px;
    padding-left: 14% !important;
    padding-right: 14% !important;
  }
}

@media screen and (max-width: 991px) {
  .kurFyt .container {
    margin-top: 15px !important;
    padding-left: 10% !important;
    padding-right: 10% !important;
  }

  .kurFyt thead tr th,
  .kurFyt tbody tr td {
    font-size: 12px;
  }
}

@media screen and (max-width: 835px) {
  .kurFyt .container {
    margin-top: 15px !important;
    padding-left: 18% !important;
    padding-right: 18% !important;
  }

  .kurFyt button {
    font-size: 13px;
  }

  .kurFyt thead tr th,
  .kurFyt tbody tr td {
    font-size: 10px;
  }
}

@media screen and (max-width: 800px) {
  .kurFyt .container {
    margin-top: 13px !important;
    padding-left: 21% !important;
    padding-right: 21% !important;
  }

  .kurFyt button {
    font-size: 12px;
  }

  .kurFyt thead tr th,
  .kurFyt tbody tr th {
    font-size: 10px;
  }
}

@media screen and (max-width: 768px) {
  .kurFyt .container {
    margin-top: 13px !important;
    padding-left: 6% !important;
    padding-right: 5% !important;
  }

  .kurFyt button {
    font-size: 13px;
  }

  .kurFyt thead tr th,
  .kurFyt tbody tr th {
    font-size: 12px;
  }
}

@media screen and (max-width: 430px) {
  .kurFyt .container {
    padding-left: 2% !important;
    padding-right: 1% !important;
  }

  .kurFyt button {
    font-size: 11px;
  }

  .kurFyt thead tr th,
  .kurFyt tbody tr th {
    font-size: 10px;
  }
}
