.vertical-fixed-right {
  position: absolute;
  top: 245px;
  right: 0;
  width: 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  padding: 0;
}


.vertical-fixed-right img {
  max-width: 100%;
  height: auto;
  object-fit: contain;
}

.ads-right-slider-container {
  display: flex;
  flex-direction: column;
  transition: transform 0.5s ease-in-out;
}

.ads-right-slide {
  width: 100%;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* @media (max-width: 1980px) {
  .vertical-fixed-right {
  top: 245px;
  }
  .vertical-fixed-right img {
    height: 900px;
  }
} */
@media (max-width: 1920px) {
  .vertical-fixed-right {
  top: 245px !important;
  }
  /* .vertical-fixed-right img {
    height: 822px;
  } */
}
@media (max-width: 1512px) {
  .vertical-fixed-right {
    top: 244px !important;
  }
  /* .vertical-fixed-right img {
    height: 810px;
  } */
}
@media (max-width: 1440px) {
  .vertical-fixed-right {
   
    top: 240px !important;
  }
  /* .vertical-fixed-right img {
    height: 797px;
  } */
}
@media (max-width: 1367px) {
  .vertical-fixed-right {
    
    top: 240px !important;
  }
  /* .vertical-fixed-right img {
    height: 775px;
  } */
}
@media (max-width: 1280px) {
  .vertical-fixed-right {
    
    top: 240px !important;
  }
  /* .vertical-fixed-right img {
    height: 745px;
  } */
}
@media (max-width: 1200px) {
  .vertical-fixed-right {
    top: 240px !important;
  }
  /* .vertical-fixed-right img {
    height: 540px;
  } */
}
@media (max-width: 1024px) {
  .vertical-fixed-right {
  top: 280px !important;
  }
  /* .vertical-fixed-right img {
    height: 520px;
  } */
}
@media (max-width: 991px) {
  .vertical-fixed-right {
    top: 280px !important;
  }
  /* .vertical-fixed-right img {
    height: 580px;
  } */
}
@media (max-width: 835px) {
  /* .vertical-fixed-right img {
    height: 660px;
  } */
  .vertical-fixed-right {
    top: 280px !important;
  }
  /* .vertical-fixed-left img {
    height: 660px;
  } */
  .vertical-fixed-left {
    top: 230px !important;
  }
}
@media (max-width: 820px) {
  .vertical-fixed-right {
    display: none;
  }
}
/* @media (max-width: 800px) {
  .vertical-fixed-right img {
    height: 650px;
  }
  .vertical-fixed-right {
    top: 232px !important;
  }
  .vertical-fixed-left img {
    height: 650px;
  }
  .vertical-fixed-left {
    top: 232px !important;
  }
} */

