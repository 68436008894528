.pharmacy-page-container{
    padding-top: 30px;
  padding-bottom: 50px;
}

.pharmacy-div-container{
    background-color: rgb(232, 232, 232);
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    min-height: 800px;
}

.pharmacy-page-input{
    background-color: #f5f5f5;
    border-radius: 10px;
    width: 100%;
    font-size: 18px;
    margin-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .pharmacy-page-title{
    width: 100%;
  color: rgb(31 41 55);
  font-weight: 700;
  font-size: 25px;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-bottom: 15px;
  text-align: center;
  background-color: #f7f7f7;
  border-top: 3px solid rgb(248 113 113);
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  }

  .pharmacy-list{
    background-color: #f5f5f5;
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 18px;
    line-height: 30px;
  }

  .pharmacy-city-list-container {
    max-height: 250px;
    overflow-y: auto;
    background-color: white;
  }
  
  .pharmacy-city-list {
    display: flex;
    flex-direction: column;
  }
  
  .pharmacy-city-item {
    padding: 10px;
    cursor: pointer;
  }
  
  .pharmacy-city-item:hover {
    background-color: #f0f0f0;
  }
  
  .pharmacy-city-item.active {
    background-color: #a4a4a4;
    color: white;
  }

  .pharmacy-district-list-container {
    max-height: 250px;
    overflow-y: auto; 
    background-color: white;
  }
  
  .pharmacy-district-list {
    display: flex;
    flex-direction: column;
  }
  
  .pharmacy-district-item {
    padding: 10px;
    cursor: pointer;
  }
  
  .pharmacy-district-item:hover {
    background-color: #f0f0f0;
  }
  
  .pharmacy-district-item.active {
    background-color: #a4a4a4;
    color: white;
  }

  @media (max-width: 1024px) {
    .pharmacy-page-container {
      padding-left: 150px;
      padding-right: 150px;
      padding-bottom: 50px;
    }
  }
  
  @media (max-width: 820px) {
    .pharmacy-page-container {
      padding-left: 80px;
      padding-right: 80px;
      padding-bottom: 50px;
    }

    .pharmacy-div-title{
        font-size: 28px;
    }
  
    
  }
  
  @media (max-width: 768px) {
    .pharmacy-page-container {
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 50px;
    }
  

    .pharmacy-div-title{
        font-size: 22px;
    }

    .pharmacy-page-title{font-size: 20px;}
  
  }