.mid-top-ads-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-bottom: 60px;
  margin-top: 50px;
}

.mid-top-ads-container a {
  width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mid-top-ads-container a img {
  width: 400px;
  height: 400px;
}

.mid-bottom-ads-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  /* margin-top: 10px; */
  margin-bottom: 10px;
}

.mid-bottom-ads-container > a > img {
  height: 400px;
  margin: 0px auto;
}

.mid-bottom-ads-container > a:nth-child(2) > img {
  display: none;
}

@media (max-width: 1920px) {
  .mid-bottom-ads-container a img {
    height: 400px;
  }
  .mid-top-ads-container a img {
    width: 400px;
    height: 400px;
  }
}
@media (max-width: 1512px) {
  .mid-bottom-ads-container a img {
    height: 300px;
  }
}

/* @media (max-width: 1440px) {
  .mid-bottom-ads-container a img {
    height: 300px;
  }
  .mid-top-ads-container a img {
    width: 300px;
    height: 300px;
  }
} */

@media (max-width: 1024px) {
  .mid-top-ads-container a img {
    /* height: 250px; */
    /* width: 250px; */
    margin-bottom: 20px;
  }
  .mid-top-ads-container {
    margin-bottom: 5px;
  }
  /* .mid-bottom-ads-container a img {
    height: 180px !important;
  } */
  .mid-bottom-ads-container2-web .ad-item img {
    /* height: 180px !important; */
    margin-left: 10%;
    margin-bottom: 4%;
  }
}
@media (max-width: 991px) {
  .reklam2 {
    display: none;
  }
  /* .mid-bottom-ads-container a img {
    height: 220px !important;
  } */

  .ad-item {
    margin-top: 2%;
  }
  /* Tüm img öğelerine stil vermek için genel sınıf */
  .ad-item img {
    object-fit: cover;
  }
  /* .ad-item:nth-child(2) {
    height: 300px !important;
  } */
  /* Özel stil: Trendsler ÜSTÜ KARE Banner için */
  /* .ad-item.reklam1 img {
    width: 300px;
    height: 300px !important;
  } */
}

@media (max-width: 820px) {
  .mid-top-ads-container a img {
    /* height: 300px; */
    width: 300px;
    margin-bottom: 5%;
  }

  .mid-bottom-ads-container > a > img {
    /* height: 250px; */
    margin-top: 5%;
  }
  
  /* .ad-item:nth-child(1) {
    height: 150px !important;
  } */
  /* Özel stil: Trendsler ÜSTÜ KARE Banner için */
  /* .ad-item.uzunbanner img {
    height: 150px !important;
  } */
}

@media (max-width: 768px) {
  .adsmidtop-container div img {
    width: 300px;
    /* height: 300px !important; */
  }
  .mid-bottom-ads-container > a > img {
    /* height: 160px; */
    margin-top: 5%;
  }
  .mid-bottom-ads-container > a:nth-child(2) > img {
    /* height: 300px !important; */
    margin-bottom: 0px;
  }

 
  /* .ad-item:nth-child(1) {
    height: 150px !important;
  } */
  /* Özel stil: Trendsler ÜSTÜ KARE Banner için */
  /* .ad-item.uzunbanner img {
    height: 150px !important;
  } */
}

@media (max-width: 768px) {
  .advertisement-x {
    width: 100%;
    height: 100%;
  }

  .advertisement-x img,
.advertisement-x iframe {
  width: 100%;
  height: 100% !important; 
  /* object-fit: cover; */
}
}

@media (max-width: 567px) {
  .mid-top-ads-container {
    margin-left: 10px;
  }

  /* .mid-bottom-ads-container > a > img {
    height: 160px;
  } */
  .mid-bottom-ads-container > a > img {
    /* height: 180px; */
    margin-top: 5%;
  }
  .mid-bottom-ads-container > a:nth-child(2) > img {
    /* height: 300px !important; */
    margin-bottom: 0px;
  }
}
@media (max-width: 430px) {
  .adsmidtop-container {
    width: 100%;
    max-height: 900px;
    display: flex;
    flex-direction: column;
    margin-top: 50px !important;
  }
  .adsmidtop-container div {
    width: 300px;
    /* height: 300px; */
    margin-left: -30%;
  }
  .adsmidtop-container div img {
    width: 300px;
    /* height: 300px !important; */
  }
  .adsmidtop-container div div {
    margin-left: 0%;
  }

  /* .mid-bottom-ads-container > a > img {
    height: 120px !important;
  } */
  .mid-bottom-ads-container > a:nth-child(2) > img {
    /* height: 300px !important; */
    margin-bottom: 0px;
  }
  /* .ad-item:nth-child(1) {
    height: 120px !important;
  } */
  /* Özel stil: Trendsler ÜSTÜ KARE Banner için */
  /* .ad-item.uzunbanner img {
    height: 120px !important;
  } */

}

@media (max-width: 390px) {
  .mid-top-ads-container {
    /* margin-top: -100px; */
    margin-left: 10px;
  }
  .adsmidtop-container div {
    width: 300px;
    /* height: 300px; */
    margin-left: -30%;
  }
  /* .mid-bottom-ads-container > a > img {
    height: 160px;
  }
  .mid-bottom-ads-container > a:nth-child(2) > img {
    height: 300px !important;
  }
  .ad-item:nth-child(1) {
    height: 100px !important;
  }

  .ad-item.uzunbanner img {
    height: 100px !important;
  } */
}
