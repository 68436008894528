.policy-page-container{
    padding: 40px 300px;
    text-align: left;
}

.policy-page-header {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
}
.policy-page-section {
    margin-bottom: 20px;
}
.policy-page-section h2 {
    font-size: 22px;
    color: #333;
    margin-bottom: 10px;
    margin-top: 40px;
    padding-bottom: 5px;
    text-align: left;
    font-weight: bold;

}
.policy-page-section p {
    text-align: left;
    margin-bottom: 10px;
}
.policy-page-section ul {
    list-style-type: disc;
    margin-left: 20px;
}
.policy-page-section ul li {
    margin-bottom: 5px;
}
.policy-page-definition {
    font-weight: bold;
}

.policy-page-link{
    font-weight: bold;
    color: #0066cc;
}

.policy-page-link:hover{
    color: #00478d;
}

@media (max-width: 1440px) {
    .policy-page-container{
        padding: 40px 250px;
        
    }
  }

@media (max-width: 1280px) {
    .policy-page-container{
        padding: 40px 230px;
        
    }
  }

  @media (max-width: 1200px) {
    .policy-page-container{
        padding: 40px 200px;
        
    }
  }

  @media (max-width: 1024px) {
    .policy-page-container{
        padding: 40px 200px;
        
    }
  }

  @media (max-width: 820px) {
    .policy-page-container{
        padding: 40px 50px;
        
    }
  }

  @media (max-width: 480px) {
    .policy-page-container{
        padding: 40px 20px;
    }
  }