.news-page {
  font-family: Arial, sans-serif;
  max-width: 1500px;
  padding: 0px;
  height: auto;
  margin: 0 auto;
  margin-top: -20px;
}

.news-page .header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.news-page .dont-miss {
  background-color: #e74c3c;
  color: white;
  padding: 8px 20px;
  font-weight: bold;
  position: relative;
  display: inline-block;
  margin-right: 22px;
}

.news-page .header {
  border-bottom: solid 2px #e74c3c;
}

.news-page .dont-miss::after {
  content: "";
  position: absolute;
  right: -15px;
  top: 0;
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 15px solid #e74c3c;
}

.news-page .dont-miss::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  bottom: 0px;
  width: 117vh !important;
  height: 2px;
}

.news-page nav {
  display: flex;
  flex-wrap: wrap;
}

.news-page nav button {
  color: #333;
  text-decoration: none;
  margin-right: 15px;
  font-size: 16px;
  position: relative;
  padding: 8px 15px;
}

.news-page nav button.active {
  color: #f73c29;
  font-weight: bold;
}

.news-page nav button.active::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #f73c29;
}

.news-page .content {
  display: flex;
  gap: 12px;
}

.news-page .featured-article {
  flex: 1;
  position: relative;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  height: 438px;
}



.news-page .article-list {
  flex: 1;
  height: 420px;
  transition: opacity 0.5s ease;
  opacity: 1;
}

.news-page .fade-in {
  opacity: 1;
}

.news-page .fade-out {
  opacity: 0;
}

.navigation-mobile {
  display: none !important;
}

.navigation-desktop .nav-button-left {
  margin-bottom: 5px;
}

.news-page .article {
  
  margin-bottom: 20px;
  cursor: pointer;
}

.news-page .article-thumbnail {
  width: 80%;
  height: 71px;
  object-fit: cover;
  margin-right: 10px;
}

.news-page .article-info {
  text-align: left !important;
  display: flex;
  align-items: center;
}

.news-page .article-info h3 {
  margin: 0;
  font-size: 14px;
  text-align: left !important;
}

.news-page .article-info p {
  margin: 5px 0 0;
  color: #888;
  font-size: 12px;
  text-align: left !important;
}

.news-page .navigation-buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.news-page .navigation-buttons button {
  background-color: #f0f0f0;
  border: none;
  padding: 5px 10px;
  margin: 0 5px;
  cursor: pointer;
}

.content .navigation {
  display: flex;
  margin: 15px 7px 20px;
  line-height: 1;
  flex-direction: column;
  margin: auto;
}

.content .navigation > span {
  padding: 8px 12px;
  display: inline-block;
  margin-right: 3px;
  cursor: pointer;
  transition: background-color 300ms, color 300ms;
  -moz-transition: background-color 300ms, color 300ms;
  -o-transition: background-color 300ms, color 300ms;
  -webkit-transition: background-color 300ms, color 300ms;
  background-color: #e9e9e9;
}

.content .navigation > span:hover {
  background-color: gray;
}

.content .navigation > span:hover i {
  color: #ffff;
}

.articles-content {
  padding-right: 0 !important;
  padding-left: 20px;
}

@media (max-width: 1680px) {
  .news-page .featured-article {
    height: 438px;
    
  }


    .news-page .article-thumbnail {
      width: 80%;
      height: 71px;
    }
  
      .news-page .article {
       
        margin-bottom: 20px;
      }
}

@media (max-width: 1512px) {
  .news-page .featured-article {
    height: 395px;
    
  }

    .news-page .article-thumbnail {
      width: 80%;
      height: 66.5px;
    }
  
      .news-page .article {
       
        margin-bottom: 15px;
      }
}

@media (max-width: 1440px) {
  .news-page .featured-article {
    height: 374px;
    
  }

  .news-page .article-thumbnail {
    width: 80%;
    height: 62.5px;
  }

    .news-page .article {
     
      margin-bottom: 15px;
    }
}

@media (max-width: 1366px) {
  .news-page .featured-article {
    height: 395px;
    
  }
}

@media (max-width: 1367px) {
  .news-page .header {
    border: none;
  }

  .news-page .featured-article {
    height: 346px;
    /* flex: unset; */
  }

  .news-page .article-thumbnail {
    width: 80%;
    height: 61px;
  }

    .news-page .article {
     
      margin-bottom: 11px;
    }

    .news-page .article-info h3 {
      font-size: 12px;
  }
}



@media (max-width: 768px) {
  .news-page {
    padding: 10px;
    margin-top: 2.2rem;
  }

  .articles-content {
    padding-left: 13px;
  }

   .news-page .dont-miss::before {
    display: none;
  }

  .news-page .dont-miss::after {
    display: none;
  }
  .news-page .dont-miss {
    background-color: white;
    color: #e74c3c;
    padding: 8px 20px;
    font-weight: bold;
    position: relative;
    display: inline-block;
    margin-right: 22px;
    border-bottom: #e74c3c solid 1px;
  }
/*
  .news-page .dont-miss {
    display: none;
  } */

  .news-page .header {
    flex-direction: column;
    align-items: center;
    border-bottom: #e74c3c solid 1px;
  }

  .news-page .dont-miss {
    margin-right: 0;
  }

  .news-page nav {
    justify-content: center;
  }

  .news-page nav button {
    font-size: 14px;
    padding: 8px;
  }

  .news-page .content {
    flex-direction: column;
  }

  .news-page .featured-article {
    height: 300px;
    flex: unset;
  }

  .news-page .article-thumbnail {
    width: 80%;
    height: 90px;
  }

  .news-page .article-info h3 {
    font-size: 15px;
  }

  .news-page .article-info p {
    font-size: 10px;
  }

  .navigation-mobile {
    display: flex !important;
    flex-direction: row !important;
    margin: 0 !important;
  }

  .navigation-desktop {
    display: none !important;
  }
}

@media (max-width: 480px) {
  .news-page nav a {
    font-size: 15px;
    padding: 4px;
  }

  .news-page .featured-article {
    height: 215px;
  }

  .news-page .article-thumbnail {
    width: 80%;
    height: 70px !important;
    object-fit: fill;
  }

  .news-page .article-info h3 {
    font-size: 14px;
  }

  .news-page .article-info p {
    font-size: 9px;
  }
}

@media (min-width: 810px) and (max-width: 834px) and (orientation: landscape),
  (min-width: 810px) and (max-width: 834px) and (orientation: portrait) {
  .shadow-md {
    display: block !important;
  }

  .news-page {
    padding: 10px;
    margin-top: 2.2rem;
  }

  .articles-content {
    padding-left: 13px;
  }

  .news-page .dont-miss::before {
    display: none;
  }

  .news-page .dont-miss::after {
    display: none;
  }

  .news-page .dont-miss {
    display: none;
  }

  .news-page .header {
    flex-direction: column;
    align-items: flex-start;
  }

  .news-page .dont-miss {
    margin-right: 0;
  }

  .news-page nav {
    justify-content: center;
  }

  .news-page nav button {
    font-size: 14px;
    padding: 8px;
  }

  .news-page .content {
    flex-direction: column;
  }

  .news-page .featured-article {
    height: 300px;
  }

  .news-page .article-thumbnail {
    width: 80px;
    height: 60px;
  }

  .news-page .article-info h3 {
    font-size: 12px;
  }

  .news-page .article-info p {
    font-size: 10px;
  }

  .navigation-mobile {
    display: flex !important;
    flex-direction: row !important;
    margin: 0 !important;
  }

  .navigation-desktop {
    display: none !important;
  }
}

@media (min-width: 834px) and (max-width: 1194px) {
  .shadow-md {
    display: block !important;
  }

  .news-page {
    padding: 10px;
    margin-top: 2.2rem;
  }

  .articles-content {
    padding-left: 13px;
  }

  .news-page .dont-miss::before {
    display: none;
  }

  .news-page .dont-miss::after {
    display: none;
  }

  .news-page .dont-miss {
    display: none;
  }

  .news-page .header {
    flex-direction: column;
    align-items: flex-start;
  }

  .news-page .dont-miss {
    margin-right: 0;
  }

  .news-page nav {
    justify-content: center;
  }

  .news-page nav button {
    font-size: 15px;
    padding: 8px;
  }

  .news-page .content {
    flex-direction: column;
  }

  .news-page .featured-article {
    height: 300px;
  }

  .news-page .article-thumbnail {
    width: 80px;
    height: 60px;
  }

  .news-page .article-info h3 {
    font-size: 17px;
  }

  .news-page .article-info p {
    font-size: 13px;
  }

  .navigation-mobile {
    display: flex !important;
    flex-direction: row !important;
    margin: 0 !important;
  }

  .navigation-desktop {
    display: none !important;
  }
}

@media (min-width: 1024px) and (max-width: 1366px) {
  .shadow-md {
    display: block !important;
  }

  .news-page {
    padding: 10px;
    margin-top: 2.2rem;
  }

  .articles-content {
    padding-left: 13px;
  }

  .news-page .dont-miss::before {
    display: block;
  }

  .news-page .dont-miss::after {
    display: none;
  }

  .news-page .dont-miss {
    display: none;
  }

  .news-page .header {
    flex-direction: column;
    align-items: flex-start;
  }

  .news-page .dont-miss {
    margin-right: 0;
  }

  .news-page nav {
    justify-content: center;
  }

  .news-page nav button {
    font-size: 19px;
    padding: 8px;
  }

  /* .news-page .content {
    flex-direction: column;
  } */

  /* .news-page .featured-article {
    height: 300px;
  } */

  /* .news-page .article-thumbnail {
    width: 80px;
    height: 60px;
  } */

  .news-page .article-info h3 {
    font-size: 14px;
  }

  .news-page .article-info p {
    font-size: 13px;
  }

  .navigation-mobile {
    display: flex !important;
    flex-direction: row !important;
    margin: 0 !important;
  }

  .navigation-desktop {
    display: none !important;
  }
}
@media (max-width:430px) {
  .news-page{
    margin-top: 50px !important;
  }
}