.traffic-page-container{
    padding-top: 30px;
  padding-bottom: 150px;
}

.city-nav-link{
    border:2px solid rgb(255, 255, 255);
    /* background-color: rgb(238, 238, 238); */
    color: rgb(50, 50, 50);
    box-sizing: border-box;
    width: 100% !important;
    height: 70px;
    text-align: start;
    align-items: end;
    display: flex;
    font-size: 22px;
    font-weight: 600;
    float: left;
    backdrop-filter: blur(2px);
    padding-bottom: 1px;
    padding-left: 7px;
}

.city-nav-link:hover, .city-nav-link:focus{
    color: black;
    box-shadow: rgb(255, 255, 255) 0px -60px 40px -28px inset;
}

.traffic-city-tab-list{
    border: 1px solid rgb(255, 255, 255);
    background-image: url("/src/components/traffic/city-images/road-network.png");
  background-size: cover;
  background-position: center;
  height: 100%;
  
   
    
}

.traffic-tab-content > .tab-pane > div{
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    
}

.traffic-page-title1 {
  width: 100%;
  color: rgb(31 41 55);
  font-weight: 700;
  font-size: 20px;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-bottom: 15px;
  text-align: center;
  background-color: #f7f7f7;
  border-top: 3px solid rgb(248 113 113);
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}

#istanbul-tab:hover, #istanbul-tab:focus{
    background-image: url("/src/components/traffic/city-images/istanbul.png");
  background-size: cover;
  background-position: center;
  height: 100%;
  transition: all 0.75s;
}

#ankara-tab:hover, #ankara-tab:focus{
    background-image: url("/src/components/traffic/city-images/ankara.png");
  background-size: cover;
  background-position: center;
  height: 100%;
  transition: all 0.75s;
}

#izmir-tab:hover, #izmir-tab:focus{
    background-image: url("/src/components/traffic/city-images/izmir.png");
  background-size: cover;
  background-position: center;
  height: 100%;
  transition: all 0.75s;
}

#bursa-tab:hover, #bursa-tab:focus{
    background-image: url("/src/components/traffic/city-images/bursa.png");
  background-size: cover;
  background-position: center;
  height: 100%;
  transition: all 0.75s;
}

#antalya-tab:hover, #antalya-tab:focus{
    background-image: url("/src/components/traffic/city-images/antalya.png");
  background-size: cover;
  background-position: center;
  height: 100%;
  transition: all 0.75s;
}

#konya-tab:hover, #konya-tab:focus{
    background-image: url("/src/components/traffic/city-images/konya.png");
  background-size: cover;
  background-position: center;
  height: 100%;
  transition: all 0.75s;
}

#adana-tab:hover, #adana-tab:focus{
    background-image: url("/src/components/traffic/city-images/adana.png");
  background-size: cover;
  background-position: center;
  height: 100%;
  transition: all 0.75s;
}

#sanliurfa-tab:hover, #sanliurfa-tab:focus{
    background-image: url("/src/components/traffic/city-images/sanliurfa.png");
  background-size: cover;
  background-position: center;
  height: 100%;
  transition: all 0.75s;
}

#gaziantep-tab:hover, #gaziantep-tab:focus{
    background-image: url("/src/components/traffic/city-images/gaziantep.png");
  background-size: cover;
  background-position: center;
  height: 100%;
  transition: all 0.75s;
}

#kocaeli-tab:hover, #kocaeli-tab:focus{
    background-image: url("/src/components/traffic/city-images/kocaeli.png");
  background-size: cover;
  background-position: center;
  height: 100%;
  transition: all 0.75s;
}

@media (max-width: 1024px) {
  .traffic-page-container {
    padding-left: 150px;
    padding-right: 150px;
    padding-bottom: 50px;
  }
}

@media (max-width: 820px) {
  .traffic-page-container {
    padding-left: 80px;
    padding-right: 80px;
    padding-bottom: 50px;
  }

  .traffic-city-tab-list{
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (max-width: 768px) {
  .traffic-page-container {
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 50px;
  }

  .traffic-city-tab-list{
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

}
