.advertisement {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: -60px;
}

.nvb {
  margin-top: -1px;
}
.tepe {
  /* display: flex; */
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden; /* Görselin taşmasını engeller */
}

.tepe img {
  max-width: 100%;
  max-height: 100%;
  height: auto; /* Oranı korur */
}

.navbar-link-container {
  background-color: #262626;
  color: gray;
  border-top: 6px #F73C29 solid;
  width: 100%;
  align-items: center;
}

.navbar-link {
  color: rgb(210, 210, 210);
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1.5px;
  margin-left: -2px;
  outline: none !important;
}

.navbar-link:hover {
  background-color: #F73C29;
  color: white;

}

.navbar-link.focus {
  outline: none !important;
  border: none !important;
}

.navbar-link.active {
  background-color: #F73C29;
  color: white;
  outline: none !important;
  border: none !important;
}

.currency-widget {
  position: absolute;
  left: 30px;
  width: 180px;
  overflow: hidden; /* Taşma olmasın */
}

.carousel-item {
  display: flex; 
  justify-content: center; 
  align-items: center;  
  height: 100%; 
  transition: transform 0.5s ease-in-out;
}

.currency-badge {
  background: rgb(219, 219, 219);
  border-radius: 5px;
  color: black;
  padding: 1px 5px;
  font-weight: 700;
  margin-right: 5px;
}

.currency-value {
  color: rgb(255, 255, 255);
  font-weight: bold;
  font-size: 16px; 
}

.currency-change {
  font-weight: bold;
  text-shadow: 1px 1px 4px #323232;
  font-size: 14px;
}

.positive-change {
  color: #7FFF00;
}

.negative-change {
  color: #FF8C8C;
}

.navbar-home-button{
  font-size: 18px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar-mini-logo{
  height: 80%;
  /* margin-right: 20px; */
}



.navbar-icon {
  color: rgb(210, 210, 210);
  margin-left: 10px;
  margin-right: 10px;
  font-size: 18px;
  padding: 0px !important;
  border: none !important;
  outline: none !important;
  height: 100%;
}

.navbar-icon:hover {
  color: white;
  transition: all 0.25s;
}

.navbar-icon:focus {
  border: none !important;
  outline: none !important;
}

.navbar-icon:active {
  border: none !important;
  outline: none !important;
}

.other-icon {
  color: rgb(210, 210, 210);
  border: none !important;
  outline: none !important;
  height: 100% !important;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1.5px;
}

.other-icon:hover {
  color: white;
  transition: all 0.25s;
}

.other-icon:focus {
  border: none !important;
  outline: none !important;
}

.other-icon:active {
  border: none !important;
  outline: none !important;
}

.navbar-dropdown-container {
  background-color: rgba(38, 38, 38, 0.85);
  backdrop-filter: blur(10px);
  border: none;
  border-radius: 0;
  height: 235px;
  width: 100vw;
  left: 0 !important;
  right: 0 !important;
  top: -4% !important;
  position: absolute;
  letter-spacing: normal;
}

.search-input{
  color: white;
  border: none;
  background: none;
  height: 30px;

}

.search-input:focus{
  border: none;
  outline: none;
}

.search-dropdown{
  background-color: rgba(38, 38, 38, 0.85);
  backdrop-filter: blur(10px);
  border: none;
  border-radius: 0;
  padding: 10px;
  border-radius: 0 0 10px 10px;
  /* top: 8px !important;   */
}

.login-modal-content{
  background-color: #262626;
  z-index: 9  !important;
  top: 45px;
}

.modal-backdrop{
  z-index: 8 !important;
}

.login-modal-content .modal-header {
  border-bottom: none;
  padding-bottom: 0px !important;
}



.login-modal-nav-item > .nav-link{
  color: gray;
  font-weight: bold;
}

.login-modal-nav-item > .nav-link.active{
  color: rgb(0, 0, 0) !important;

}

.login-modal-close-btn{
 background-color: white;
 outline: none !important;
 border: none !important;
 box-shadow: none !important;
}

.login-modal-check{
  box-shadow: none !important;
  border: none !important;
  outline: none !important;
  height: 20px;
  width: 20px;
  background-color: #dd867c;
  margin-right: 10px;
}

.login-modal-check:checked {
  background-color: #db4939 !important;
}

.login-modal-input{
  width: 100%;
  height: 35px;
  background-color: #dadada;
  border-radius: 5px;
  padding-left: 10px;
  margin-bottom: 10px;
  margin-top: 5px;
}

.login-modal-input:focus{
  outline: 2px solid white;
}

.login-modal-submit-btn{
  background-color: #db4939;
  padding: 5px;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 5px;
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 15px;
  transition: all 0.2s;
  color: white;
}

.login-modal-submit-btn:hover{
  background-color: #912418;
}

.navbar-weather{
right: 10px;
}

.weather-dropdown{
  width: 220px;
  background-color: rgba(38, 38, 38, 0.85);
  backdrop-filter: blur(10px);
  border: none;
  border-radius: 0;
  border-radius: 0 0 10px 10px;
  top: 5px !important;
  max-height:300px;
   overflow:auto;
   z-index: 49 !important;
  position: absolute;
}

.weather-search-input{
  color: white;
  border: none;
  background: none;
  height: 30px;
  backdrop-filter: blur(5px);
}

.weather-search-input:focus{
  border: none;
  outline: none;
}

.weather-city-name, .weather-temperature{
  width: 112px;
}


.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 20px;
}

.logo {
  height: auto;
  width: 19%;
  margin-top: 5px;
  margin-right: 30px;
}

/* .navbar-content{
  color: red;
  height: 300px;
} */

/* .scrolled-content {
  height: 55px !important;
  margin-bottom: 60px; padding-bottom: 70px;
  color: blue;
} */

@media (max-width: 1440px) {
  .navbar-link, .other-icon{
    font-size: 12px;
  }

  .navbar-icon{
    font-size: 15px;
  }

}

@media (max-width: 1351px) {
  .navbar-link, .other-icon{
    font-size: 11px;
  }

  .navbar-icon{
    font-size: 14px;
  }

}

@media (max-width: 1295px) {
  .navbar-link, .other-icon{
    font-size: 10px;
  }

  .navbar-icon{
    font-size: 14px;
  }

}

@media (max-width: 1245px) {
  .navbar-link, .other-icon{
    font-size: 9px;
  }

  .navbar-icon{
    font-size: 12px;
  }

}

@media (max-width: 1205px) {
  .navbar-link, .other-icon{
    font-size: 8px;
  }

  .navbar-icon{
    font-size: 12px;
  }

}

@media (max-width: 1160px) {
  .navbar-link, .other-icon{
    font-size: 7px;
  }

  .navbar-icon{
    font-size: 12px;
  }

}

@media (max-width: 1024px) {
  .navbar-link-container{
    padding-left: 20px;
    padding-right: 20px;
  }

  .navbar-weather{
    position: relative;
    right: 0px;
  }

  .navbar-icon{
    font-size: 14px;
    margin-left: 3px;
    margin-right: 3px;
  }

  .mobile-currency-weather{
    background-color: rgba(38, 38, 38, 0.85);
  backdrop-filter: blur(10px);
  border: none;
  height: 55px;
  padding-left: 200px;
  padding-right: 200px;
  }

  .navbar-weather-web,
  .currency-widget-web {
    display: none;
  }

  .currency-widget{
    position: unset;

  }

  .currency-widget>.carousel-inner{
    width: 120px;
  }

  .currency-badge{
    font-size: 12px;
  }

  .currency-value {
    font-size: 12px;
  }

  .weather-city-name, .weather-temperature{
    width: 88px;
  }

  .weather-icon{

    width: 25px;
  }

  /* .weather-city-name, .weather-temperature{
    font-size: 11px;
  } */

  .navbar-dropdown-container{
    padding-left: 20px;
    padding-right: 20px;
    justify-content: center;

  }

  .navbar-link{
    font-size: 10px;
  }

  .other-icon{
    font-size: 10px;
  }

  .navbar-link > div{
    padding: 5px;
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .navbar-home-button{
    width: 30px;

  }


}

@media (max-width: 840px) {
  .mobile-currency-weather{
    display: grid;
    z-index: 49 !important;
    position: absolute;
    width: 100%;
    
  }

  /* .weather-dropdown{
    position: fixed;
  }

  .navbar-weather  {
    position: fixed;
  } */

  
}

@media (max-width: 820px) {

  

  .navbar-dropdown-container{
    top: 35%;
  }

  .navbar-weather-web, .currency-widget-web{
    display: none;
  }

  /* .weather-city-name, .weather-temperature{
    font-size: 16px;
  } */

  

  .other-icon{
    display: none;
  }

  .navbar-icon{
    margin-left: 7px;
    margin-right: 7px;
    font-size: 16px;
  }


}

@media (max-width: 768px) {
  .advertisement {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 1px;
    margin-right: 0px !important;
  }

  .login-modal-content{
    
    top: 0;
  }

  .center-content {
    flex-direction: column;
    gap: 10px;
  }

  .logo {
    width: 30%;
    margin: 0;
  }

  .navbar-weather-web, .currency-widget-web{
    display: none;
  }

  .mobile-currency-weather{
    background-color: rgba(38, 38, 38, 0.85);
  backdrop-filter: blur(10px);
  border: none;
  height: 55px;
  padding-left: 20px !important;
  padding-right: 20px !important;
  }



  .search-dropdown{
    top: 3 !important;
  }

  .mobile-logo{
    height: 40px !important;
  }

  .navbar-dropdown-container{
    height: auto !important;
  }

  .mobile-dropdown-container{padding-left: 10px;
    padding-right: 10px;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-template-rows: repeat(2, minmax(0, 1fr));
    font-size: 12px;
    justify-content: space-between;
    }

    .responsive-column{
      flex: 1 0 33%;
      box-sizing: border-box;
    }
    .weather-dropdown {
      z-index: 49 !important;
    }
    /* .text-white{
      z-index: 999 !important;
    } */
    /* *{
      z-index: 999 !important;
    } */

}
/* .leading-8{
  padding: 90px;
} */

@media (max-width:480px) {
  
  .mobile-menu-social-links{
    gap: 15px !important;
    bottom: 50px !important;
  }}

@media (max-width:430px) {
  .mobile-menu-content{
    height: 70px;
  }
  .mobile-menu-social-links{
    gap: 15px !important;
    bottom: 50px !important;
  }

  .navbar-link-container{
    margin-bottom: 55px;
  }

  .mobile-currency-weather{
    margin-top: -55px;
  }
}
@media (max-width:390px) {
  .mobile-menu-content{
    height: 70px;
  }
  .mobile-menu-social-links{
    gap: 10px !important;
    bottom: 45px !important;
  }
}

@media (max-width:360px) {
  .mobile-menu-content{
    height: 65px;
  }
  .mobile-menu-social-links{
    gap: 10px !important;
    bottom: 45px !important;
  }
}

