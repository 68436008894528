

@media screen and (max-width: 1024px) {
    .about-container {
        padding-left: 200px;
        padding-right: 200px;
    }
    
}

@media screen and (max-width: 820px) {
    .about-container {
        padding-left: 80px;
        padding-right: 80px;
    }
}

@media screen and (max-width: 768px) {
    .about-container {
        padding-left: 15px;
        padding-right: 15px;
    }
}