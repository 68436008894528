.adsPopupCenter {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1050; /* Ensure it is on top of other elements */
}

.adsPopupCenter .modal {
  position: relative;
  max-width: 1000px; /* Set max-width to 1000px */
  max-height: 1000px; /* Set max-height to 1000px */
  width: 100%;
  height: auto;
  margin: 0 auto;
  /* background-color: white; */
  border-radius: 8px;
  
}

.adsPopupCenter .modal-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.adsPopupCenter .modal-content {
  position: relative;
  width: 100%;
  height: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.adsPopupCenter .close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.adsPopupCenter .modal-body {
  padding: 0;
  text-align: center;
}

.adsPopupCenter .img-fluid {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain; /* Ensure image scales properly */
}


@media (max-width: 1920px) {
  .adsPopupCenter .modal-content .modal-body {
    max-width: 800px !important;
  }
}

@media (max-width: 1512px) {
  .adsPopupCenter .modal-content .modal-body {
    max-width: 700px !important;
  }
}

@media (max-width: 1280px) {
  .adsPopupCenter .modal-content .modal-body {
    max-width: 600px !important;
  }
}

@media (max-width: 820px) {
  .adsPopupCenter .modal-content .modal-body {
    max-width: 600px !important;
  }
}

@media (max-width: 768px) {
  .adsPopupCenter  .modal-content .modal-body {
    max-width: 600px !important;
  }
}

@media (max-width: 430px) {
  .adsPopupCenter  .modal-content {
    width: 300px;
  }
  .adsPopupCenter .modal-content .modal-body {
    max-width: 300px !important;
  }
}

@media (max-width: 360px) {
  .adsPopupCenter .modal-content {
    width: 250px;
  }
  .adsPopupCenter .modal-content .modal-body {
    max-width: 250px !important;
  }
}
