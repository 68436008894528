.search-page-top-left-container {
    border-right: 1px solid rgb(209, 209, 209);
    padding-top: 30px;
    padding-bottom: 60px;
    text-align: start;
}

.search-page-top-right-container {
    padding-top: 30px;
    padding-bottom: 60px;
    text-align: start;
}

.search-news-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
    width: 100%;
    margin-bottom: 30px;
}

.search-news-grid-container {
    width: 100%;
}

.search-news-item {
    position: relative;
    border-radius: 0.5rem;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
    transition: box-shadow 0.3s ease-in-out;
    z-index: 0 !important;
}

.search-news-item:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.search-news-item img {
    width: 100%;
    height: 180px;
    object-fit: cover;
    transition: transform 0.3s ease-in-out;
}

.search-news-item:hover img {
    transform: scale(1.05);
}

.search-news-content {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0.5rem;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.8), transparent);
    color: white;
}

.search-page-title1 {
    width: 100%;
    color: rgb(31 41 55);
    font-weight: 700;
    font-size: 20px;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-bottom: 15px;
    text-align: center;
    background-color: #f7f7f7;
    border-top: 3px solid rgb(248 113 113);
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}


.date {
    font-size: 0.75rem;
    margin-bottom: 0.25rem;
}

.search-news-grid-container .title {
    font-size: 1rem;
    font-weight: 700;
    margin: 0;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.8), transparent);
    padding: 0.5rem 1rem;
    display: inline-block;
    margin-bottom: -6px;
    width: 115%;
    margin-left: -20px;
    height: 3em;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    white-space: normal;
    text-overflow: ellipsis;
}

.search-news-item:hover {
    cursor: pointer;
}

.search-page-load-more-button {
    font-weight: 700;
    color: white;
    border-radius: 50px;
    background-color: #F73C29;
    height: 40px;
    padding-left: 10px;
    padding-right: 10px;
}

.search-page-load-more-button:hover {
    background-color: #b93021;
}

@media (max-width: 1024px) {

    .search-page-container,
    .search-page-top-container,
    .search-page-bottom-container {
        padding-left: 80px;
        padding-right: 80px;
    }
}

@media (max-width: 820px) {
    .search-page-container {
        padding-left: 80px;
        padding-right: 80px;
    }

    .search-page-top-container {
        grid: unset;
        padding: 0;
        column-span: unset;
    }

    .search-page-top-left-container {
        border: none;

    }

    .search-page-top-right-container {
        padding: 0;
        grid: unset;
        grid-column: span 7 / span 7;
        margin-bottom: 20px;
    }

    .search-page-top-left-container {
        padding-right: 0;
    }
}


@media (min-width: 769px) and (max-width: 1024px) {
    .search-news-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (min-width: 1025px) {
    .search-news-grid {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 768px) {
    .search-news-grid {
        grid-template-columns: repeat(2, 1fr);
    }

    .search-news-item img {
        height: 120px;

    }

    .search-page-container,
    .search-page-top-container,
    .search-page-bottom-container {
        padding-left: 15px;
        padding-right: 15px;
        border: none;
        padding-bottom: 20px;
    }


    .search-page-top-left-container {
        border: none;
    }
}